<template>
    <btn
        :label="label"
        :tag="tag"
        :href="href"
        class="c-btn__arrow"
        :class="{ 'c-btn__arrow--looped': isModal }"
        :colour="colour"
        :is-external="true"
        icon-after="arrow-up"
        :is-modal="isModal"
        :is-looper="true"
        @clicked="openModal"
    >
    </btn>
</template>

<script>
import Btn from "components/Btn.vue";

export default {
    components: { Btn },
    props: {
        ...Btn.props,
        colour: {
            type: String,
            required: false,
            default: null,
        },
        isModal: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        openModal() {
            this.$store.dispatch("modal/open");
        },
    },
};
</script>

<style lang="scss">
.c-btn__arrow {
    --btn-icon-rotate: 0deg;
    --font-size: 7rem;
    @include transform(translateY(5rem));
    font-size: var(--font-size);
    text-transform: uppercase;

    padding: 0;
    width: fit-content;

    font-family: ff("heading");
    line-height: 1;

    opacity: 0;

    cursor: pointer;

    @media #{md("xs")} {
        --font-size: 9rem;
    }

    &--looped {
        --btn-icon-rotate: 45deg;
    }

    .c-btn__icon {
        @include transform(translate(-5rem, 5rem));

        svg {
            @include transition(0.5s ease all);
            @include transform(rotate(var(--btn-icon-rotate)));
        }
    }
}
</style>
