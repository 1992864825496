<template>
    <div class="c-contact-address">
        <a
            v-if="mapUrl"
            class="c-contact-address__copy t-cms"
            :href="mapUrl"
            target="_blank"
            rel="noopener"
            :aria-label="$t('contact.buttons.address.aria-label')"
        >
            {{ address }}
        </a>
        <p v-else class="c-contact-address__copy t-cms" :href="mapUrl" target="_blank" rel="noopener">
            {{ address }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        address: {
            type: String,
            required: false,
            default: null,
        },
        coordinate: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        mapUrl() {
            return this.coordinate.length
                ? `https://www.google.com/maps?q=${this.coordinate[0].lat},${this.coordinate[0].lng}`
                : null;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-contact-address {
    --contact-address-opacity: 1;

    @include contact-block-pre-animation;

    display: flex;
    max-width: 20rem;

    &:hover {
        --contact-address-opacity: 0.5;
    }
    &__copy {
        @include transition(0.3s ease opacity);
        color: var(--text-colour);
        opacity: var(--contact-address-opacity);
    }
}
</style>
