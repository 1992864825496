<template>
    <button
        class="c-switch-btn"
        :class="{ 'c-switch-btn--switching': isSwitchingSite }"
        :aria-label="$t('shared.switchBtn.aria', { restaurantName: siteName })"
        @click="switchSite"
    >
        <btn tag="div" class="c-switch-btn__btn" :label="siteName" icon-before="arrow-up" icon-after="arrow-up" />
    </button>
</template>

<script>
import Btn from "components/Btn";
export default {
    components: { Btn },
    props: {
        ...Btn.props,

        siteName: {
            type: String,
            required: false,
            default: "Honō Ramen",
        },
        isSwitchingSite: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    methods: {
        switchSite() {
            // mock animation
            this.$store.commit("global/setSwitchingWebsite", true);
        },
    },
};
</script>

<style lang="scss">
.c-switch-btn {
    --max-width-switch-btn: 30rem;
    --after-opacity: 1;
    position: absolute;

    display: flex;
    justify-content: center;

    height: 100%;
    max-height: var(--height-site-left-clipped);
    width: 100%;

    top: 0;
    left: 0;
    right: 0;

    cursor: pointer;

    font-size: 2rem;
    font-weight: bold;

    z-index: 1; // ensure that the button will go below the fake iframe

    &:after {
        position: absolute;
        content: " ";
        left: 0rem;
        right: 0rem;
        top: 5rem;
        background: var(--next-site-accent-color);
        height: 30rem;
        width: 100%;
        z-index: 99;
        opacity: var(--after-opacity);
    }
    &--switching {
        --after-opacity: 0;
    }

    @media #{md('xs')} {
        --max-width-switch-btn: var(--width-site-left-clipped);
        @include transform(translate(0, var(--width-site-left-clipped)) rotate(-90deg));
        transform-origin: top left;

        max-width: var(--width-site-left-clipped);

        right: 0;
    }

    &__btn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        position: relative;
        height: 100%;
        width: 100%;
        max-width: var(--max-width-switch-btn);

        padding: var(--grid-gutter-half) var(--grid-gutter-half);
        @media #{md('xs')} {
            padding: 0px var(--grid-gutter-half);
        }
    }

    .c-btn__icon {
        @include transition(0.5s ease all);
        @include transform(rotate(var(--rotation-arrow)) scale(1.3));
        &.-before {
            display: none;
        }
        @media #{md('xs')} {
            &.-after {
                display: none;
            }
            &.-before {
                display: inline-block;
            }
        }
    }

    .c-btn__label {
        margin-top: 0.6rem; // I am not exacily sure why but the line height weirdly made on this font? so I added a margin to align it visually.

        line-height: 1.9rem;
        text-align: left;
        @media #{md('xs')} {
            max-width: 7ch; // Mo code, I try different thinks I think Its good this way
        }
    }

    .o-icon svg {
        --svg-width: 2rem;
    }
}
</style>
