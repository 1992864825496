<template>
    <li
        class="c-splash-page-list-main"
        :class="`c-splash-page-list-main--${site.id}`"
        :style="`--splash-list-color: ${site.textColour}; --splash-list-accent-colour: ${site.accentColour};`"
    >
        <button
            :aria-label="$t('splash.aria-label', { siteName: convertIdToHeading(site.id) })"
            :disabled="isErrorPage ? true : false"
            @mouseenter="toggleUserOver(true)"
            @mouseleave="toggleUserOver(false)"
            @click="clicked"
        >
            <!-- logo -->
            <ul class="c-splash-page-list-main__inner">
                <li class="c-splash-page-list-main__inner__logos">
                    <!-- icon -->
                    <icon class="c-splash-page-list-main__inner__logos__icon" icon="hono" />

                    <splash-page-list-main-logo
                        :site-id="site.id"
                        :is-error-page="isErrorPage"
                        :fill-colour="fillColour"
                        :is-hovering="isHovering || isTransitionRunning"
                        :duration="duration"
                    />
                </li>
                <li class="c-splash-page-list-main__inner__arrow">
                    <!-- arrow -->
                    <icon
                        v-if="!isErrorPage"
                        class="c-splash-page-list-main__inner__arrow__svg"
                        :class="{
                            'c-splash-page-list-main__inner__arrow__svg--hovering': isHovering || isTransitionRunning,
                        }"
                        icon="arrow-right"
                    />
                </li>
            </ul>
        </button>
    </li>
</template>

<script>
import humanizePageIdsMixin from "mixins/humanizePageIdsMixin.js";
import { mapState } from "vuex";

import { gsap, CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);

import Icon from "objects/Icon";

import SplashPageListMainLogo from "components/SplashPage/SplashPageList/SplashPageListMainLogo/SplashPageListMainLogo";
export default {
    mixins: [humanizePageIdsMixin],
    components: {
        SplashPageListMainLogo,
        Icon,
    },
    props: {
        fillColour: {
            type: String,
            required: false,
            default: "#000000",
        },
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false,
        },
        site: {
            type: Object,
            required: false,
            default: null,
        },
        duration: {
            type: Number,
            required: false,
            default: 0.7,
        },
    },
    data() {
        return {
            isHovering: false,
            isTransitionRunning: false,
            timeLineTransition: null,
        };
    },
    computed: {
        ...mapState({
            isSplashDisabled: (state) => state.splash.isSplashDisabled,
        }),
    },
    mounted() {
        this.$nextTick(() => {
            this.initPageTransition();
            this.isErrorPage ? (this.isHovering = true) : null;
        });
    },
    beforeDestroy() {
        this.destroyTimeline();
    },
    methods: {
        ////////////////////////////////
        //       START RUN ANIMATION ON HOVER
        ////////////////////////////////
        toggleUserOver(bool) {
            !this.isErrorPage ? (this.isHovering = bool) : null;
        },
        ////////////////////////////////
        //       END RUN ANIMATION ON HOVER
        ////////////////////////////////

        ////////////////////////////////
        //       START PAGE TRANSITION
        ////////////////////////////////
        initPageTransition() {
            this.timeLineTransition = gsap.timeline({
                paused: true,

                onComplete: () => {
                    this.toggleTransitionStartedGlobally(false);
                    this.redirectToSite();
                },
            });

            this.isErrorPage ? null : this.whichAnimation();
        },
        whichAnimation() {
            CustomEase.create("titleScroll", "0.43, 0.00, 0.09, 1.00");
            switch (this.site.id) {
                case 1:
                    this.timeLineTransition
                        .to(
                            `.c-splash-page-list--1`,
                            {
                                y: "100vh",
                                zIndex: 9,

                                ease: "titleScroll",
                                duration: 1.5,
                            },
                            "transition"
                        )

                        .to(
                            `.c-splash-page-list--2`,
                            {
                                y: "50vh",

                                ease: "titleScroll",
                                duration: 1.5,
                            },
                            "transition"
                        )
                        .add(() => {
                            this.setNextSiteGlobally();
                        });
                    break;

                default:
                    this.timeLineTransition
                        .to(`.p-splash`, {
                            backgroundColor: "#000000",

                            ease: "none",
                            duration: 0,
                        })
                        .to(
                            `.c-splash-page-list--2`,
                            {
                                y: "-100vh",
                                zIndex: 9,

                                ease: "titleScroll",
                                duration: 1.5,
                            },
                            "transition"
                        )
                        .to(
                            `.c-splash-page-list--1`,
                            {
                                y: "-50vh",

                                ease: "titleScroll",
                                duration: 1.5,
                            },
                            "transition"
                        )

                        .add(() => {
                            this.setNextSiteGlobally();
                        });
                    break;
            }
        },

        setNextSiteGlobally() {
            this.$store.commit("splash/setWhichSiteIsSelected", this.site.id);
        },
        redirectToSite() {
            // mock wait that the animation in SplashPageHeadingTransition.vue is done
            setTimeout(() => {
                window.location.href = this.site.baseUrl;
            }, 1600);
        },
        ////////////////////////////////
        //       END PAGE TRANSITION
        ////////////////////////////////

        ////////////////////////////////
        //       START TRIGGERED PAGE TRANSITION
        ////////////////////////////////
        clicked() {
            !this.isSplashDisabled || !this.isErrorPage ? this.playPageTransitionTimeline() : null;
        },
        playPageTransitionTimeline() {
            this.toggleTransitionStartedGlobally(true);
            this.isTransitionRunning = true;
            this.timeLineTransition.play();
        },
        toggleTransitionStartedGlobally(bool) {
            this.$store.commit("splash/setDisabledSplash", bool);
        },

        ////////////////////////////////
        //       END TRIGGERED PAGE TRANSITION
        ////////////////////////////////

        destroyTimeline() {
            this.timeLineTransition ? (this.timeLineTransition.kill(), (this.timeLineTransition = null)) : null;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-splash-page-list-main {
    @include splash-page-grid;
    justify-content: center;

    button {
        cursor: pointer;
        &:disabled {
            cursor: inherit;
        }
    }

    &__inner {
        overflow: hidden;

        &__logos {
            --splash-page-list-main-logo-column: 1/2;
            --splash-page-list-main-logo-row: 1/3;
            display: grid;
            grid-column: var(--splash-page-list-main-logo-column);
            grid-row: var(--splash-page-list-main-logo-row);
            grid-template-columns: repeat(7, 1fr);

            gap: var(--grid-gutter-half);
            flex-direction: row;
            flex: 1;

            @media #{md("sm")} {
                --splash-page-list-main-logo-column: 1/8;
                --splash-page-list-main-logo-row: 1/2;
            }

            &__icon {
                position: relative;
                color: var(--splash-list-accent-colour);
                padding-right: var(--grid-gutter-half);
                &::v-deep svg {
                    --icon-width: 120%;
                    --icon-top: 0%;
                    position: absolute;
                    top: var(--icon-top);
                    height: calc(100% - (var(--icon-top) * 2));
                    width: var(--icon-width);

                    @media #{md("xs")} {
                        --icon-width: 100%;
                        // padding-bottom: 0.3rem;
                    }

                    @media #{md("sm")} {
                        --icon-top: 7%;
                        // padding-bottom: 0.3rem;
                    }
                }
            }
        }

        &__arrow {
            --splash-page-list-main-arrow-column: 1/2;
            --splash-page-list-main-arrow-row: 3/4;
            --max-height-arrow: 4.5rem;
            --rotation-arrow: 90deg;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            grid-column: var(--splash-page-list-main-arrow-column);
            grid-row: var(--splash-page-list-main-arrow-row);
            min-width: 7rem;
            .c-splash-page-list-main--2 & {
                --rotation-arrow: -90deg;
            }

            @media #{md("sm")} {
                --splash-page-list-main-arrow-column: 8/9;
                --splash-page-list-main-arrow-row: 1/2;
                --max-height-arrow: 7rem;
            }
            &__svg {
                @include transition(0.5s ease transform);

                &--hovering {
                    @include transform(rotate(var(--rotation-arrow)));
                }
            }
            &::v-deep svg {
                --svg-width: 7rem;
                max-height: var(--max-height-arrow);
                width: 100%;
                color: var(--splash-list-color);
            }
        }
    }
}
</style>
