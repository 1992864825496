<template>
    <div class="c-contact-block" ref="contactBlock">
        <div class="c-contact-block__icon">
            <icon :icon="`${whichLogo}-logo`" :style="`font-size: 7rem; ${logoColor};`" />
        </div>
        <div class="c-contact-block__contacts">
            <contact-address v-if="settings.address" :address="settings.address" :coordinate="settings.map" />
            <contact-buttons v-if="settings" :settings="settings" />
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap/all";
import { mapState } from "vuex";

import ContactAddress from "components/Contact/ContactAddress";
import ContactButtons from "components/Contact/ContactButtons";
import Icon from "objects/Icon";

export default {
    name: "ContactBlock",
    components: { ContactAddress, ContactButtons, Icon },
    computed: {
        ...mapState({
            currentSiteId: (state) => state.global.currentSite.id,
            isPageRevealed: (state) => state.reveals.isPageRevealed,
            currentSiteTextColour: (state) => state.global.currentSite.textColour,
            settings: (state) => state.global.settings,
        }),
        logoColor() {
            return `color: var(--color-${this.currentSiteTextColour})`;
        },
        isCurrentSiteRamen() {
            return this.currentSiteId === 2;
        },
        whichLogo() {
            return this.isCurrentSiteRamen ? "ramen" : "hono";
        },
    },

    mounted() {
        this.triggerAnimation();
    },
    watch: {
        isPageRevealed() {
            this.triggerAnimation();
        },
    },

    beforeDestroy() {
        this.destroyTimeline();
    },
    methods: {
        triggerAnimation() {
            this.isPageRevealed && !this.timeline ? this.reveal() : null;
        },
        reveal() {
            const durationContactBlock = 0.5;
            this.tl = gsap
                .timeline()
                .to(".c-contact-block__icon", {
                    scale: 1,
                    duration: durationContactBlock,
                    opacity: 1,
                    ease: "power1.out",
                })
                .to(
                    ".c-contact-address",
                    {
                        y: 0,
                        opacity: 1,
                        duration: durationContactBlock,

                        ease: "power1.out",
                    },
                    "-=0.4"
                )
                .to(
                    ".c-contact-buttons li",
                    {
                        y: 0,
                        opacity: 1,
                        duration: durationContactBlock,
                        stagger: 0.1,

                        ease: "power1.out",
                    },
                    "-=0.4"
                );
        },
        destroyTimeline() {
            this.tl ? (this.tl.kill(), (this.tl = null)) : null;
        },
    },
};
</script>

<style lang="scss">
.c-contact-block {
    --contact-block-justify-content: space-between;
    display: flex;
    justify-content: var(--contact-block-justify-content);
    align-items: flex-end;
    margin-bottom: var(--grid-gutter);

    @media #{md("xs")} {
        --contact-block-justify-content: flex-start;
        padding-right: var(--grid-gutter);
    }

    &__icon {
        // translate and opacity for the animation
        @include transform(scale(0));
        transform-origin: 2rem 50%; // looks better this way
        opacity: 0;

        @media #{md("xs")} {
            padding-right: var(--grid-gutter-3X);
        }
    }

    &__contacts {
        --contact-block-gap: var(--grid-gutter-3X);
        display: grid;
        gap: var(--contact-block-gap);
        @media #{md("xs")} {
            --contact-block-gap: 10rem;
        }
    }
}
</style>
