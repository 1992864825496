<template>
    <div class="p-splash" :class="{ 'p-splash--ready': isReady }">
        <ul v-if="honoSites.length && !whichSiteIsSelected" key="splash-list" class="p-splash__list">
            <!-- izakaya -->
            <!-- ramen -->
            <splash-page-list v-for="(site, index) in honoSites" :key="index" :site="site" :image="setImage(index)" />
        </ul>
        <splash-page-heading-transition
            v-else-if="honoSites.length && whichSiteIsSelected"
            key="splash-transition"
            :site="honoSites[whichSiteIsSelected - 1]"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import SplashPageHeadingTransition from "components/SplashPage/SplashPageHeadingTransition";
import SplashPageList from "components/SplashPage/SplashPageList/SplashPageList";

export default {
    name: "Splash",
    components: {
        SplashPageHeadingTransition,
        SplashPageList,
    },
    computed: {
        ...mapState({
            whichSiteIsSelected: (state) => state.splash.whichSiteIsSelected,
        }),
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null,
        };
    },

    data() {
        return {
            displayPageTimeout: null,
            honoSites: [],
            isReady: false,
            isSplashVisible: true,
        };
    },
    props: {
        page: Object,
    },

    mounted() {
        this.setHonoSites();
        this.displayPage();
    },
    beforeDestroy() {
        this.timeoutDestroyer();
    },
    methods: {
        ////////////////////////////////
        //       START SET BASE
        ////////////////////////////////
        setHonoSites() {
            this.honoSites = window.__initialData__.sites.filter((site) => site.handle !== "restos_fr");
        },
        setImage(index) {
            return index === 0 ? this.page.izakayaImage : this.page.ramenImage; // I don't like that. I should come back later for that.
        },
        ////////////////////////////////
        //       END SET BASE
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION RELATED METHODS
        ////////////////////////////////
        displayPage() {
            this.displayPageTimeout = setTimeout(() => {
                this.isReady = true;
                this.timeoutDestroyer();
            }, 500);
        },
        timeoutDestroyer() {
            this.displayPageTimeout ? (clearTimeout(this.displayPageTimeout), (this.displayPageTimeout = null)) : null;
        },
        ////////////////////////////////
        //       END ANIMATION RELATED METHODS
        ////////////////////////////////
    },
};
</script>

<style lang="scss" scoped>
.p-splash {
    --opacity-page: 0;
    --splash-pointer-events: none;
    @include transition(0.5s ease opacity);
    @include reset-list;
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    opacity: var(--opacity-page);
    pointer-events: var(--splash-pointer-events);

    &--ready {
        --opacity-page: 1;
        --splash-pointer-events: inherit;
    }

    &__list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
</style>
