/*
 * Loader
 */

// State
const state = {
    loading: 0,
    firstload: true,
}

// Getters
const getters = {
    isLoading: state => state.loading > 0,
    loadCount: state => state.loading,
}

// Actions
const actions = {
    startLoad(store) {
        store.commit('updateLoad', store.state.loading + 1)
    },
    endLoad(store) {
        store.commit('updateLoad', store.state.loading - 1)
    },
    resetLoad(store) {
        store.commit('updateLoad', 0)
    },
    firstLoaded(store) {
        store.commit('setFirstLoad', false)
    }
}

// Mutations
const mutations = {
    updateLoad(state, value) {
        // Uncomment for debug
        //console.log('updateLoad', value)
        state.loading = value
    },
    setFirstLoad(state, isFirstLoaded) {
        state.firstload = isFirstLoaded
    }
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
