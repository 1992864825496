<template>
    <div class="c-schedule" v-if="schedule">
        <div class="c-schedule__heading t-t2">{{ $t("schedule.businessHours") }}</div>
        <div class="c-schedule__table">
            <div v-for="day in schedule" :key="day.weekday" class="c-schedule__table-row">
                <div class="c-schedule__table-col col-1">
                    {{ day.weekday }}
                </div>
                <div class="c-schedule__table-col col-2">
                    {{ day.hours }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
export default {
    props: {
        schedule: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapState({
            isPageRevealed: (state) => state.reveals.isPageRevealed,
        }),
    },
    mounted() {
        this.triggerAnimation();
    },
    watch: {
        isPageRevealed() {
            this.triggerAnimation();
        },
    },
    beforeDestroy() {
        this.timeline ? this.timeline.kill : "";
    },
    data() {
        return {
            timeline: null,
        };
    },
    methods: {
        triggerAnimation() {
            this.isPageRevealed && !this.timeline ? this.reveal() : null;
        },
        reveal() {
            this.timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: ".c-schedule__heading",
                    start: "top bottom-=20%",
                },
            });

            this.timeline
                .to(".c-schedule__heading", {
                    opacity: 1,
                    y: 0,
                    duration: 0.7,
                    ease: "power1.out",
                })
                .to(".c-schedule__table-row", {
                    opacity: 1,
                    y: 0,
                    duration: 0.7,
                    stagger: 0.1,
                    paddingBottom: "20px",
                    ease: "power1.out",
                });

            // console.log('Schedule revealed');
        },
    },
};
</script>

<style lang="scss">
.c-schedule {
    display: flex;
    flex-direction: column;
    width: 30rem;

    &__heading {
        @include contact-block-pre-animation;
    }

    &__table {
        display: grid;
        margin-top: 2rem;

        &-row {
            // for the animation
            opacity: 0;
            padding-bottom: 0px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding-bottom: 2rem;
            padding-top: 2rem;
            border-bottom: 1px solid currentColor;
        }

        .col-1 {
            font-weight: bold;
        }
    }
}
</style>
