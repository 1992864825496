<template>
    <li
        class="c-food-menu-group-child"
        :class="{
            'c-food-menu-group-child--sub-group': isSubGroup,
            'c-food-menu-group-child--two-columns': isTwoColumn,
        }"
    >
        <!-- switch -->
        <div v-if="isSubGroup" class="c-food-menu-group-child__sub-group">
            <!-- title -->
            <food-menu-child-title :title="child.name" />
            <!-- list -->
            <ul v-if="child.children" class="c-food-menu-group-child__sub-group__list">
                <food-menu-group-child-element v-for="(item, index) in child.children" :key="index" :child="item" />
            </ul>
        </div>
        <ul v-else>
            <food-menu-group-child-element :child="child" />
        </ul>
    </li>
</template>

<script>
import FoodMenuChildTitle from "components/FoodMenu/FoodMenuChildTitle";
import FoodMenuGroupChildElement from "components/FoodMenu/FoodMenuGroupChildElement/FoodMenuGroupChildElement";
export default {
    components: {
        FoodMenuChildTitle,
        FoodMenuGroupChildElement,
    },
    props: {
        child: {
            type: Object,
            required: false,
            default: null,
        },
    },
    computed: {
        isSubGroup() {
            return this.child.typeHandle === "subGroup";
        },
        isTwoColumn() {
            return this.child.displaySmall;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-food-menu-group-child {
    --food-menu-group-child-margin: var(--grid-gutter-half);
    --food-menu-group-child-column: column;
    --food-menu-group-child-css-column: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media #{md("sm")} {
        --food-menu-group-child-margin: var(--grid-gutter);
    }

    &--sub-group {
        margin-bottom: var(--food-menu-group-child-margin);
    }

    &--two-columns {
        margin-top: calc(var(--food-menu-group-child-margin) / 2);
        --food-menu-group-child-css-column: 1;
        // display: wrap;
        // @media #{md("sm")} {
        //     --food-menu-group-child-column: row;
        // }
        @media #{md("sm")} {
            --food-menu-group-child-css-column: 2;
        }
    }

    &__sub-group {
        &__list {
            display: inline-block;
            width: 100%;
            column-count: var(--food-menu-group-child-css-column);
            column-gap: var(--grid-gutter);
        }
    }
}
</style>
