<template>
    <div
        class="c-splash-page-list-main-logo"
        :class="[`c-splash-page-list-main-logo--${siteId}`, { 'c-splash-page-list-main-logo--error': isErrorPage }]"
        :style="`--fill: ${fillColour}`"
    >
        <shared-looper :is-hovering="isHovering" :fill-colour="fillColour" :duration="duration">
            <svg viewBox="0 0 966 433" xmlns="http://www.w3.org/2000/svg">
                <use :xlink:href="isErrorLogo"></use>
            </svg>
        </shared-looper>
    </div>
</template>

<script>
import humanizePageIdsMixin from "mixins/humanizePageIdsMixin.js";

import SharedLooper from "components/Shared/SharedLooper.vue";
export default {
    mixins: [humanizePageIdsMixin],
    props: {
        siteId: {
            type: Number,
            required: true,
        },
        isHovering: {
            type: Boolean,
            required: false,
            default: false,
        },
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false,
        },
        fillColour: {
            type: String,
            required: false,
            default: "#000000",
        },
        duration: {
            type: Number,
            required: false,
            default: 0.7,
        },
    },
    components: {
        SharedLooper,
    },
    data() {
        return {
            lengthList: 2,
            timelineMarkee: null,
        };
    },

    computed: {
        isErrorLogo() {
            return this.isErrorPage
                ? this.convertSiteNameToLogoName("error")
                : this.convertSiteNameToLogoName(this.convertIdToHeading(this.siteId).toLowerCase());
        },
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.c-splash-page-list-main-logo {
    --splash-page-list-logo-column: 2/8;
    grid-column: var(--splash-page-list-logo-column);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    height: 100%;
}
</style>
