<template>
    <app-section class="p-error">
        <transition name="fade">
            <div v-if="dataIsReady" class="p-error__inner" key="errorInner">
                <splash-page-list-main
                    :site="site"
                    :is-error-page="true"
                    :fill-colour="site.textColour"
                    :duration="1.5"
                />
                <error-bottom :site="site" />
            </div>
        </transition>
    </app-section>
</template>

<script>
import { SECTION_HANDLES } from "src/constants";

import AppSection from "layout/AppSection";
import ErrorBottom from "components/Error/ErrorBottom";
import SplashPageListMain from "components/SplashPage/SplashPageList/SplashPageListMain";

export default {
    name: "Error",
    components: {
        AppSection,
        ErrorBottom,
        SplashPageListMain,
    },
    props: {
        entry: Object,
    },

    mounted() {
        this.$nextTick(() => {
            this.setWhichSite();
        });
    },
    computed: {},
    data() {
        return {
            SECTION_HANDLES,
            site: {},
            dataIsReady: false,
        };
    },
    methods: {
        setWhichSite() {
            // use the global values
            this.setCurrentSite();
            this.indexCurrentSite() < 2 ? this.toggleDataIsReady(true) : this.setSplashPageColours();
        },
        setCurrentSite() {
            this.site = JSON.parse(JSON.stringify(window.__initialData__.sites[this.indexCurrentSite()]));
        },
        indexCurrentSite() {
            return window.__initialData__.currentSite.id - 1; // -1 one because index start at 1 instead of 0
        },

        setSplashPageColours() {
            this.site.backgroundColour = window.__initialData__.sites[0].backgroundColour;
            this.site.textColour = window.__initialData__.sites[0].textColour;
            this.site.accentColour = window.__initialData__.sites[0].accentColour;
            this.toggleDataIsReady(true);
        },
        toggleDataIsReady(bool) {
            setTimeout(() => {
                this.dataIsReady = bool;
            }, 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
.p-error {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    &__inner {
        @include transform(translateY(0rem));
        padding-top: var(--grid-gutter);

        .c-splash-page-list-main {
            justify-content: flex-start;

            &::v-deep button {
                width: 100%;
            }
            &::v-deep .-hono {
                // left: -10%;
                @media #{md("md")} {
                    @include transform(
                        translateX(-15%)
                    ); // because svg is 100% but the path is centered, so it doesn't look aligned to the left
                }
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    $fade-transition: linear 0.5s opacity, ease 0.5s transform;
    @include transition($fade-transition);
}
.fade-enter,
.fade-leave-to {
    @include transform(translateY(5rem));
    opacity: 0;
}
</style>
