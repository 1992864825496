<template>
    <tag
        :is="tag"
        :href="tag == 'a' && href !== null ? href : false"
        :to="tag == 'router-link' && href !== null ? href : false"
        :rel="tag == 'a' ? 'noopener' : false"
        :class="classes"
        :target="isExternal ? '_blank' : null"
        :style="`--btn-text-colour: ${colour}; --btn-bg-colour:${background}; `"
        :arial-label="label"
        @click="clicked"
        @mouseenter="toggleUserOver(true)"
        @mouseleave="toggleUserOver(false)"
    >
        <icon v-if="iconBefore !== null" class="c-btn__icon -before" :icon="iconBefore" />

        <div v-if="isLooper" class="c-btn__label">
            <shared-looper :is-hovering="isHovering" :is-infinite="false">
                <span> {{ label }}</span>
            </shared-looper>
        </div>
        <span v-else class="c-btn__label">
            <slot>{{ label }}</slot>
        </span>
        <icon v-if="iconAfter !== null" class="c-btn__icon -after" :icon="iconAfter" />
    </tag>
</template>

<script>
import Icon from "objects/Icon";
import SharedLooper from "components/Shared/SharedLooper.vue";

export default {
    name: "Btn",
    components: {
        Icon,
        SharedLooper,
    },
    props: {
        tag: {
            type: String,
            default: "button",
        },
        href: {
            type: String,
            default: null,
        },
        label: {
            type: String | Number,
            default: "Button Label",
        },
        iconBefore: {
            type: String,
            default: null,
        },
        iconAfter: {
            type: String,
            default: null,
        },

        isRounded: {
            type: Boolean,
            required: false,
            default: false,
        },
        colour: {
            type: String,
            required: false,
            default: "#000000",
        },
        background: {
            type: String,
            required: false,
            default: "#ffffff",
        },
        isSwitchColourOnHover: {
            type: Boolean,
            required: false,
            default: false,
        },
        isExternal: {
            type: Boolean,
            required: false,
            default: false,
        },
        isModal: { type: Boolean, required: false, default: true },
        isLooper: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isHovering: false,
        };
    },
    computed: {
        classes() {
            let className = "c-btn";
            if (this.bgColor) className += ` -${this.bgColor}`;
            if (this.isRounded) className += ` -rounded`;
            if (this.isSwitchColourOnHover) className += ` -switch-colour`;

            return className;
        },
    },
    methods: {
        clicked() {
            this.isModal ? this.$emit("clicked") : null;
        },
        ////////////////////////////////
        //       START RUN ANIMATION ON HOVER
        ////////////////////////////////
        toggleUserOver(bool) {
            this.isHovering = bool;
        },
        ////////////////////////////////
        //       END RUN ANIMATION ON HOVER
        ////////////////////////////////
    },
};
</script>

<style lang="scss">
.c-btn {
    --btn-text-colour: var(--color-dark);
    --btn-bg-colour: var(--color-light);

    --btn-colour: var(--btn-text-colour);
    --btn-background: var(--btn-bg-colour);

    @include transition(0.5s ease background-color);

    display: inline-flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;
    padding: 0.5em 2em;

    cursor: pointer;

    &.-switch-colour {
        &:hover {
            --btn-background: var(--btn-text-colour);
            --btn-colour: var(--btn-bg-colour);
        }
    }

    &.-rounded {
        @include shadow(inset 0px 0px 0px 1px var(--btn-text-colour));
        @include radius($border-radius);

        padding: 0.75rem 1.25rem;
        background-color: var(--btn-background);
    }

    &__label {
        @include transition(0.5s ease color);
        color: var(--btn-colour);

        .c-btn__arrow & {
            display: block;
            line-height: 7rem;
        }
        span {
            padding-bottom: 1rem;
        }
        & + .c-btn__icon {
            margin-left: 2rem;
        }
    }
    &__icon {
        & + .c-btn__label {
            margin-left: 2rem;
        }
    }
}
</style>
