<template>
    <div class="c-food-menu-child-element-option">
        <ul class="c-food-menu-child-element-option__list">
            <li v-for="(item, index) in options" :key="index">
                &#32;<icon
                    class="c-food-menu-child-element-option__list__icon c-food-menu-child-element-option__list__icon--vegan"
                    icon="vegan"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import Icon from "objects/Icon";
export default {
    components: {
        Icon,
    },
    props: {
        options: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.c-food-menu-child-element-option {
    display: inline-block;
    &__list {
        @include reset-list;
        display: flex;
        flex-direction: row;
        padding-left: 0.5rem;
        &__icon {
            max-width: 1.5rem;
        }
    }
}
</style>
