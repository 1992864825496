<template>

    <div class="l-builder">
        <slot name="beforeSections"/>
        <section
            v-for="(section, i) in sections"
            class="-s"
            :key="`section-${i}`"
            :id="sectionId(section)"
        >
            <div class="-s__title" v-if="section.label && section.displayLabel">
                <span class="t-t4">{{ section.label }}</span>
            </div>

            <template v-if="section.children.length > 0">
                <div
                    v-for="(block, i) in section.children"
                    :key="`block-${i}`"
                    class="-b"
                    :class="block.typeHandle | snake"
                >
                    <component
                        :is="block.typeHandle"
                        :data="block"
                    />
                </div>
            </template>
        </section>
    </div>

</template>

<script>

import BlockAccordions from 'blocks/BlockAccordions'
import BlockContent from 'blocks/BlockContent'
import BlockGallery from 'blocks/BlockGallery'
import BlockHeading from 'blocks/BlockHeading'
import BlockImage from 'blocks/BlockImage'
import BlockSpacer from 'blocks/BlockSpacer'

import { toKebab } from 'src/utils'

export default {
    name: 'AppBuilder',
    props: {
        sections: {
            type: Array,
            default: () => [],
        }
    },
    components: {

        BlockAccordions,
        BlockContent,
        BlockGallery,
        BlockHeading,
        BlockImage,
        BlockSpacer
    },
    methods: {
        sectionId(section) {
            return `section-${toKebab(section.label) || section.id}`
        },
    }
};

</script>

<style lang="scss">
.l-builder {

    .-s {
        --block-spacer: var(--grid-gutter);
        --section-spacer: calc( var(--block-spacer) * 2);

        &:not(:first-of-type) {
            padding-top: var(--section-spacer);
        }

        &__title {
        }

        .-b {
            &:nth-child(n + 2) {
                margin-top: var(--block-spacer);
            }

            &:nth-last-child(n + 2) {
                @media #{md("xs", "max")} {
                    margin-bottom: var(--block-spacer);
                }
            }

            & + .block-boxed-content {
                @media #{md("xs", "max")} {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
