import snippets from "./snippets";
import getAsset from "./assets";

/*
 * Craft GraphQL Queries
 */

/**********************************
 *
 *
 *  GENERAL
 *
 *
 **********************************/

// Main query
export const general = `{
    navs: globalSet(handle: "navs") {
        ... on navs_GlobalSet {
            menuId: relationEntryMenu {
                id
            }
            navNodes {
                ...on navNodes_internal_BlockType {
                    entry {
                        id
                        section: sectionHandle
                    }
                    label
                }
                ...on navNodes_external_BlockType {
                    id
                    externalLink,
                    label
                }
            }
        }
    }
    settings: globalSet(handle: "settings") {
        ... on settings_GlobalSet {
            address
            ${snippets.map}
            phone
            email
            ${snippets.schedule}
        }
    }
}`;

/**********************************
 *
 *
 *  SEARCH
 *
 *
 **********************************/

export const categoryGroup = `query Categories($group: [String!], $relations: [EntryCriteriaInput]){
    categories(group: $group, relatedToEntries: $relations) {
        id
        title
        handle: slug
    }
}
`;

export const searchEntries = (section, options) => {
    if (section == undefined) {
        throw "A section is required to perform the searchEntries query";
    }

    let appendQuery = "";

    if (options.relatedToCategories && options.relatedToCategories.length) {
        let relatedToCategories = [];

        options.relatedToCategories.forEach((categ) => {
            let category = `{
                group: ["${categ.group}"],
                id: [${categ.id}]
           }`;

            relatedToCategories.push(category);
        });

        appendQuery += `, relatedToCategories: [${relatedToCategories}]`;
    }
    if (options.searchText) {
        appendQuery += `, search: "${options.searchText}"`;
    }

    if (options.fixedOrder) {
        appendQuery += `, fixedOrder: ${options.fixedOrder}`;
    } else {
        let orderKey = options.order ? options.order.key : "postDate",
            orderDirection = options.order ? options.order.direction : "DESC";

        let orderBy = `${orderKey} ${orderDirection}`;
        appendQuery += `, limit: ${options.limit || 100}, offset: ${options.offset || 0}`;
        appendQuery += `, orderBy: "${orderBy}"`;
    }

    if (options.ids) {
        let _ids = options.ids.map((id) => parseInt(id));

        _ids.forEach((id) => {
            if (isNaN(id)) throw "ids should be of type Number";
        });
        appendQuery += `, id: [${_ids}]`;
    }

    return `{
        totalCount: entryCount(section: "${section}")
        resultsCount: entryCount(section: "${section}" ${appendQuery})
        entries(section: "${section}", ${appendQuery}) {
            ${snippets.entry}

        }
    }`;
};

/**********************************
 *
 *
 *  CONTENT
 *
 *
 **********************************/

// Homepage
export const home = `{
    entry(section: "home") {
        ${snippets.defaultData}
        section: sectionHandle

        ...on home_home_Entry {
            heading
            ${snippets.seo}
            bigImage: ${getAsset("imageHomeLarge", "big")}
            smallImage: ${getAsset("imageHomeSmall", "square")}
            description: body
        }
    }
}`;

// Splash
export const splash = `{
    entry(section: "splash") {
        ${snippets.defaultData}
        section: sectionHandle

        ...on splash_splash_Entry {
            ${snippets.seo}
            izakayaImage: ${getAsset("image", "splash")}
            ramenImage: ${getAsset("imageSplashPage", "splash")}
        }
    }
}`;

// Homepage
export const error404 = `{
    entry(section: "error404") {
        ${snippets.defaultData}
        section: sectionHandle
    }
}`;

// Menus
export const menu = (id) => `{
    entry(section: "menu", id: ${id}) {
        ${snippets.menu}
    }
}`;

// Pages
export const page = (slug) => `{
    entry(section: "page", slug: "${slug}") {
        ${snippets.page}
    }
}`;

export default {
    // GENERAL
    general,

    // SEARCH
    categoryGroup,
    searchEntries,

    // CONTENT
    home,
    splash,
    error404,
    menu,
    page,
};
