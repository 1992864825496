<template>
    <div class="c-home-left" ref="homeLeft" :class="{ 'c-home-left--visible': isVisible }">
        <div class="c-home-left__inner">
            <div class="c-home-left__inner__logo" :style="`--min-width: ${minWidth}`">
                <span ref="leftLogo">
                    <b> {{ this.splitSiteName(0) }}</b>
                    <b>{{ this.splitSiteName(2) }}</b>
                </span>
            </div>
            <div v-html="page.description" class="c-home-left__inner__text t-cms"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default {
    props: {
        page: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            isVisible: false,
            timeline: null,
            minWidth: "auto",
        };
    },
    computed: {
        ...mapState({
            currentSite: (state) => state.global.currentSite,
        }),
    },

    mounted() {
        this.$nextTick(() => {
            this.revealItems();
            this.setMinWidthLogo();
        });
    },
    beforeDestroy() {
        this.destroyTimeline();
        this.onResize();
    },
    methods: {
        splitSiteName(index) {
            return this.currentSite.name.split(/(\s+)/)[index];
        },
        revealHandle(state) {
            this.isVisible = state.isActive;
        },
        setMinWidthLogo() {
            this.minWidth = `${this.$refs.leftLogo.clientWidth}px`;
        },

        revealItems() {
            const homeLeft = this.$refs.homeLeft;
            this.tl = gsap
                .timeline({
                    scrollTrigger: {
                        trigger: homeLeft,
                        start: "top bottom-=20%",
                    },
                })
                .to(homeLeft, {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    delay: 0.3,
                    ease: "power1.out",
                });
        },

        destroyTimeline() {
            this.timeline ? (this.timeline.kill(), (this.timeline = null)) : null;
        },
        onResize() {
            this.setMinWidthLogo();
        },
    },
};
</script>

<style lang="scss" scoped>
.c-home-left {
    --home-left-max-width: calc(100vw - var(--grid-gutter));
    @include contact-block-pre-animation;
    display: grid;
    gap: calc(2 * var(--grid-gutter));
    margin-bottom: var(--grid-gutter);
    max-width: var(--home-left-max-width);

    @media #{md("sm")} {
        --home-left-max-width: auto;
    }

    &__inner {
        display: flex;
        align-items: flex-start;

        &__logo {
            display: flex;
            width: auto;
            min-width: var(--min-width);
            span {
                --margin-right: var(--grid-gutter-half);
                @include transform(rotate(180deg));
                @include responsive-type($min-width: 375, $max-width: 1280, $min-font: 40, $max-font: 130);
                display: block;
                margin-right: var(--margin-right);
                font-weight: bold;
                writing-mode: vertical-rl;
                line-height: 90%;
                pointer-events: none;
                @media #{md("sm")} {
                    --margin-right: var(--grid-gutter);
                }
            }
            b {
                display: block;
            }
        }

        &__text {
            max-width: 35rem;
        }
    }
}
</style>
