<template>
    <li class="c-food-menu-group">
        <div class="c-food-menu-group__title">
            <!-- title -->
            <h2>{{ item.name }}</h2>
            <!-- # of brochette -->
        </div>
        <!-- children -->
        <ul class="c-food-menu-group__children">
            <food-menu-group-child v-for="(child, index) in item.children" :key="index" :child="child" />
        </ul>
    </li>
</template>

<script>
import FoodMenuGroupChild from "components/FoodMenu/FoodMenuGroupChild";
export default {
    components: {
        FoodMenuGroupChild
    },
    props: {
        item: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.c-food-menu-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    // break-inside: avoid-column;
    page-break-inside: avoid;

    &__title {
        padding-top: var(--grid-gutter-third); // avoid title top to be cut with the column
        h2 {
            @include t2;
        }
    }

    &__children {
        --c-food-menu-group-line: none;
        @include reset-list;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        margin: var(--grid-gutter) 0 calc(var(--grid-gutter) - var(--grid-gutter-third)) 0; //margin bottom === margin top - the padding top of the title of the next section (need to avoid title to be cut)

        @media #{md("xs")} {
            --c-food-menu-group-line: block;
        }
    }
}
</style>
