import Vue from "vue";
import { linkResolver } from "src/router";

// Prefix url for translation
Vue.filter("resolver", function (handle, slug) {
    if (!handle) {
        return "/";
    }

    return linkResolver(handle, slug);
});

// Prefix url for translation
Vue.filter("tel", (number) => {
    // Remove all non numeric characters
    number = number.replace(/\D/g, "");

    number = `tel:+1${number}`;
    return number;
});

Vue.filter("humanizePhone", (phone) => {
    return phone.replace(/[^0-9]/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
});

//
Vue.filter("toBr", (str) => {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
});

Vue.filter("snake", (str) => {
    return str
        .replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join("-");
});

// Capitalize string
Vue.filter("capitalize", (str) => str.charAt(0).toUpperCase() + str.slice(1));
