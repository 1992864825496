<template>
    <div
        v-if="data && data.heading"
        class="b-heading"
    >
        <h2 class="t-t4" v-html="data.heading" />
    </div>
</template>

<script>
export default {
    name: "BlockHeading",
    components: {
    },
    props: {
        data: false
    }
}
</script>

<style lang="scss">
.b-heading {
    max-width: 20em;
}
</style>
