<template>
    <footer class="l-footer">
        <!-- <span class="l-footer__copy">&copy; {{ year }}</span> -->
    </footer>
</template>

<script>

export default {
    name: 'AppFooter',
    computed: {
        year: () => new Date().getFullYear(),
    },
};

</script>

<style lang="scss">

.l-footer {}

.l-footer__copy {}

</style>
