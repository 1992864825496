<template>
    <ul class="c-shared-looper">
        <li
            v-for="index in lengthList"
            :key="index"
            :ref="`splashLogo_${index}`"
            class="c-shared-looper__items"
            :class="{ 'c-shared-looper__items--looped': isLooped(index) }"
        >
            <slot></slot>
        </li>
    </ul>
</template>

<script>
import { gsap } from "gsap";
import humanizePageIdsMixin from "mixins/humanizePageIdsMixin.js";
export default {
    mixins: [humanizePageIdsMixin],
    props: {
        isHovering: {
            type: Boolean,
            required: false,
            default: false,
        },
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false,
        },
        fillColour: {
            type: String,
            required: false,
            default: "#000000",
        },
        isInfinite: {
            type: Boolean,
            required: false,
            default: true,
        },
        duration: {
            type: Number,
            required: false,
            default: 0.7,
        },
    },
    data() {
        return {
            lengthList: 2,
            timelineMarkee: null,
        };
    },
    watch: {
        isHovering(bool) {
            this.togglePlay(bool);
        },
    },
    mounted() {
        this.initMarkee();
    },
    beforeDestroy() {
        this.destroyTimeline();
    },
    computed: {},
    methods: {
        isLooped(index) {
            return index > 1;
        },

        ////////////////////////////////
        //       START INIT MARKEE
        ////////////////////////////////
        initMarkee() {
            this.timelineMarkee = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.isHovering && this.isInfinite ? this.timelineMarkee.restart() : null;
                },
            });
            this.timelineMarkee
                .to(
                    this.$refs.splashLogo_1,
                    {
                        y: "-100%",

                        ease: this.customEase(),
                        duration: this.duration,
                    },
                    "splash"
                )
                .to(
                    this.$refs.splashLogo_2,
                    {
                        y: "0%",

                        ease: this.customEase(),
                        duration: this.duration,
                    },
                    "splash"
                );
        },
        customEase() {
            return this.isInfinite ? "none" : "power1.inOut";
        },
        togglePlay(bool) {
            bool && !this.timelineMarkee.isActive() ? this.timelineMarkee.restart() : null;
        },

        destroyTimeline() {
            this.timelineMarkee ? (this.timelineMarkee.kill(), (this.timelineMarkee = null)) : null;
        },

        ////////////////////////////////
        //       END INIT MARKEE
        ////////////////////////////////
    },
};
</script>

<style lang="scss" scoped>
.c-shared-looper {
    @include reset-list;
    display: grid;
    grid-auto-flow: row;
    gap: var(--grid-gutter);

    position: relative;

    height: 100%;
    width: 100%;
    max-width: 100%;

    right: 0px;
    left: 0px;
    overflow: hidden;

    &__items {
        --max-width-splash-logo: 100%;
        display: flex;
        flex: 1;
        justify-content: flex-start;
        width: 100%;
        @media #{md("sm")} {
            --max-width-splash-logo: 80%; // there is a bug I can't understand, the svg below keep being wider than the parent
        }

        &--looped {
            @include transform(translateY(100%));
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0.1rem;
        }
        &::v-deep svg {
            position: relative;
            // height: auto;
            width: 100%;
            height: 100%;
            max-height: 100%;
            max-width: 80%;
            overflow: visible;
            .c-splash-page-list-main-logo--error & {
                fill: var(--fill); // only for the 404 page
            }

            .p-error__inner & {
                // https://mambomambo-team.atlassian.net/browse/HONO-136
                margin-bottom: 1rem;
            }
        }
    }
}
// }
</style>
