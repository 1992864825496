<template>
    <app-section>
        <app-builder
            v-if="page.sections && page.sections.length"
            :sections="page.sections"
        />
    </app-section>
</template>

<script>
import AppSection from 'layout/AppSection'

import { SECTION_HANDLES } from 'src/constants'
import AppBuilder from 'layout/AppBuilder.vue'

export default {
    name: 'Page',
    components: {
        AppSection,
        AppBuilder
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    })
};

</script>

<style lang="scss">

.p-page {

}

</style>
