import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            getSiteById: "global/getSiteById",
        }),
    },
    methods: {
        convertIdToSite(id) {
            return this.getSiteById(id);
        },
        convertIdUpComingSite(id) {
            return id === 1 ? this.getSiteById(2) : this.getSiteById(1);
        },

        convertIdToHeading(id) {
            switch (id) {
                case 1:
                    return "Izakaya";
                case 2:
                    return "Ramen";

                default:
                    return "Splash";
            }
        },

        convertSiteNameToLogoName(name) {
            return `#${name.toLowerCase()}-logo`;
        },
    },
};
