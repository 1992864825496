<template>
    <div class="c-food-menu-child-group-title">
        <h3>{{ title }}</h3>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.c-food-menu-child-group-title {
    --food-menu-child-group-title-margin: var(--grid-gutter-half);
    @include shadow(inset 0px 0px 0px 1px rgba(0, 0, 0, 1));
    @include radius(var(--grid-gutter-2X));
    padding: 1rem 1.5rem 0.6rem 1.5rem;
    margin-bottom: var(--food-menu-child-group-title-margin);
    @media #{md("sm")} {
        --food-menu-child-group-title-margin: var(--grid-gutter);
    }
}
</style>
