<template>
    <app-section class="p-bem">

        <div>
            <div>
                <contact-block />
                <app-nav />
                <asset :asset="{ url: 'https://source.unsplash.com/random/401x400'}" />
            </div>
            <schedule :schedule="schedule" />
        </div>
    </app-section>
</template>

<script>
import AppSection from 'layout/AppSection'
import AppNav from 'layout/AppNav'
import Schedule from 'components/Schedule'
import ContactBlock from 'components/ContactBlock'
import Asset from 'objects/Asset'

export default {
    components: {
        AppSection,
        ContactBlock,
        AppNav,
        Schedule,
        Asset
    },
    data: () => ({
        schedule: [
            {
                name: 'Sunday',
                hours: '17h30–22h30'
            },
            {
                name: 'Monday',
                hours: '17h30–22h30'
            },
            {
                name: 'Tuesday',
                hours: 'Closed'
            },
            {
                name: 'Wednesday',
                hours: '17h30–22h30'
            },
            {
                name: 'Thursday',
                hours: '17h30–22h30'
            },
            {
                name: 'Friday',
                hours: '17h30–22h30'
            },
            {
                name: 'Saturday',
                hours: '17h30–22h30'
            }
        ]
    })

}
</script>

<style lang="scss">
.p-bem {
    min-height: 100vh;
    margin-top: 5rem;


}

</style>
