<template>
    <nav class="l-nav" ref="nav">
        <ul v-if="nav" class="l-nav__list">
            <shared-btn-arrow
                v-if="menuId"
                class="l-nav__link l-nav__list__item"
                :label="$t(`modal.open.label`)"
                tag="button"
                section-name="menu"
                :is-modal="true"
                :colour="currentSiteTextColour"
            />
            <li v-for="(navItem, index) in nav" :key="index" class="l-nav__list__item">
                <shared-btn-arrow
                    class="l-nav__link"
                    :label="navItem.label"
                    :tag="navItem.uri ? 'a' : 'button'"
                    :href="navItem.uri"
                    :section-name="navItem.section"
                    :is-modal="isItemModal(navItem.section)"
                    :colour="currentSiteTextColour"
                />
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapState } from "vuex";
import SharedBtnArrow from "components/Shared/SharedBtnArrow";
import { gsap } from "gsap/all";

export default {
    components: { SharedBtnArrow },
    data() {
        return {
            timeline: null,
        };
    },
    computed: {
        ...mapState({
            currentSiteTextColour: (state) => state.global.currentSite.textColour,
            isPageRevealed: (state) => state.reveals.isPageRevealed,
            nav: (state) => state.global.navs,
            menuId: (state) => state.global.settings.menuId,
        }),
    },
    beforeDestroy() {
        this.timeline ? (this.timeline.kill(), (this.timeline = null)) : "";
    },
    mounted() {
        this.triggerAnimation();
    },
    watch: {
        isPageRevealed() {
            this.triggerAnimation();
        },
    },
    methods: {
        triggerAnimation() {
            this.isPageRevealed && !this.timeline ? this.reveal() : null;
        },
        reveal() {
            this.timeline = gsap.timeline();

            this.timeline
                .to(
                    ".l-nav__link",
                    {
                        opacity: 1,
                        y: 0,
                        ease: "power4.out",
                        duration: 1,
                        stagger: 0.1,
                    },
                    "animationStarted"
                )
                .to(
                    ".l-nav__list .c-btn__icon",
                    {
                        y: 0,
                        x: 0,
                        duration: 1,
                        ease: "power4.out",
                    },
                    "animationStarted"
                );
        },

        isItemModal(section) {
            // refactor here if you want to trigger more modals
            return section === "menu";
        },
    },
};
</script>

<style lang="scss">
.l-nav {
    &__list {
        @include reset-list;
        &__item {
            @media #{md("sm")} {
                padding-bottom: 0.3rem;
            }
        }
    }
}
.c-btn {
    --app-nav-button-top: 1rem;
    --app-nav-button-bottom: 1rem;
    &__icon {
        margin-top: var(--app-nav-button-top);
    }
    &__label span {
        padding: 0rem;

        @media #{md("xs")} {
            padding: var(--app-nav-button-top) 0rem var(--app-nav-button-bottom) 0rem;
        }
    }
}
</style>
