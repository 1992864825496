<template>
    <div
        class="b-image"
        :class="{'-zoom': zoom}"
    >

        <lightbox
            v-if="zoom"
            ref="zoom"
            :items="data.image"
            :index="zoomIndex"
            @close="zoomIndex = null"
        />
        <div
            @click="zoomIndex = 0"
        >
            <asset
                :asset="data.image"
            />
        </div>
    </div>
</template>

<script>
import Asset from 'objects/Asset'
import Lightbox from 'components/Lightbox'

export default {
    name: 'BlockImage',
    components: {
        Asset,
        Lightbox
    },
    data: () => ({
        zoomIndex: null
    }),
    props: {
        data: false,
        zoom: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        items() {
            return [this.data.image]
        }
    },
    watch: {
        zoomIndex($v) {
            if (this.$refs.zoom)
                this.$refs.zoom.index = $v
        }
    }
}
</script>

<style lang="scss">
    .b-image {
        &.-zoom {
            > div {
                cursor: zoom-in;
            }
        }
    }
</style>
