<template>
    <ul class="c-food-menu-group-child-element-extra">
        <li v-for="(extra, index) in extras" :key="index" class="c-food-menu-group-child-element-extra__list">
            <ul>
                <li>
                    <small
                        ><b>{{ extra.name }}</b></small
                    >
                </li>
                <li>
                    <small
                        ><b>{{ extra.price }}</b></small
                    >
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        extras: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.c-food-menu-group-child-element-extra {
    @include reset-list;
    display: flex;
    flex-direction: column;
    &__list {
        display: flex;
        flex-direction: column;

        &:first-child {
            padding-top: var(--grid-gutter-fifth);
        }

        ul {
            @include food-menu-list-element;
        }
    }
}
</style>
