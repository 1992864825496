import getAsset from "./assets";

/*
 * Craft GraphQL Snippets
 */

/**********************************
 *
 *
 *  DEFAULTS
 *
 *
 **********************************/

// Default data
const defaultData = `
id
uri
`;

// Default entry
const entry = `
id
section: sectionHandle
title
slug
uri
`;

// Default category
const category = `
id
slug
title
`;

//Schedule
const schedule = `
schedule {
    ...on schedule_day_BlockType {
        weekday
        hours
    }
}
`;

/**********************************
 *
 *
 *  ATOMS
 *
 *
 **********************************/

// SEO
const seo = `
seo {
    title
    description
}
`;

// Map (SuperTable)
const map = `
map {
    ...on map_BlockType {
        lng
        lat
    }
}
`;

/**********************************
 *
 *
 *  ASSET
 *
 *
 **********************************/

/**********************************
 *
 *
 *  RELATIONS
 *
 *
 **********************************/

/**********************************
 *
 *
 *  BUILDER
 *
 *
 **********************************/

// Accordions
const blockAccordions = `
...on layout_BlockAccordions_BlockType {
    typeHandle
    openFirst: trueOrFalse1
    accordions: children {
        ...on layout_accordion_BlockType {
            heading
            content: body
        }
    }
}
`;

// Content
const blockContent = `
...on layout_BlockContent_BlockType {
    typeHandle
    content: body
}
`;

// Heading
const blockHeading = `
...on layout_BlockHeading_BlockType {
    typeHandle
    heading
}
`;

// Gallery
const blockGallery = `
...on layout_BlockGallery_BlockType {
    typeHandle
    ${getAsset("images", "big")}
}
`;

// Image
const blockImage = `
...on layout_BlockImage_BlockType {
    typeHandle
    ${getAsset()}
}
`;

// Spacer
const blockSpacer = `
...on layout_BlockSpacer_BlockType {
    typeHandle
}
`;

// Section
const blockSection = `
...on layout_BlockSection_BlockType {
    typeHandle
    id
    label
    displayLabel: trueOrFalse1
    children {
        ${blockAccordions}
        ${blockContent}
        ${blockHeading}
        ${blockGallery}
        ${blockImage}
        ${blockSpacer}
    }
}
`;

// Layout
const layout = `
sections: layout {
    ${blockSection}
}
`;

/**********************************
 *
 *
 *  MENU
 *
 *
 **********************************/

const element = `
...on menuElements_element_BlockType {
    typeHandle
    name: label
    price
    details
    description
    extras {
        name
        price
    }
    options
}
`;

const subGroup = `
...on menuElements_subGroup_BlockType {
    typeHandle
    name: label
    displaySmall: trueOrFalse1
    children {
        ${element}
    }
}
`;

const group = `
...on menuElements_group_BlockType {
    typeHandle
    name: label
    children {
        ${subGroup}
        ${element}
    }
}
`;

const menuElements = `
menuElements {
    ${group}
}
`;

/**********************************
 *
 *
 *  CHANNELS AND STRUCTURES
 *
 *
 **********************************/

const menu = `
id
...on menu_default_Entry {
    ${menuElements}
}
`;

const page = `
${entry}
...on page_default_Entry {
    ${layout}
    ${seo}
    children {
        ${entry}
    }
    parent {
        title

        #...on page_default_Entry {
        #}
        children {
            ${entry}
        }
    }
}
`;

// Exports
export default {
    // Defaults
    defaultData,
    entry,
    category,

    // Atoms
    seo,
    map,
    schedule,

    // CHANNELS
    menu,
    page,
};
