<template>
    <section class="c-home-schedule">
        <div class="c-home-schedule__img">
            <asset :asset="image" :to-reveal="true" ref="scheduleImg" />
        </div>
        <schedule :schedule="schedule" ref="schedule" />
    </section>
</template>

<script>
import { mapState } from "vuex";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import Asset from "objects/Asset";
import Schedule from "components/Schedule";
export default {
    components: {
        Asset,
        Schedule,
    },
    props: {
        image: {
            type: Array,
            required: false,
            default: () => [],
        },
        isHeadingRevealed: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    mounted() {
        this.triggerAnimation();
    },

    computed: {
        ...mapState({
            settings: (state) => state.global.settings,
        }),
        schedule() {
            return this.settings.schedule;
        },
    },
    watch: {
        isPageRevealed() {
            this.triggerAnimation();
        },
    },
    methods: {
        triggerAnimation() {
            this.isPageRevealed ? this.revealContent() : null;
        },
        revealContent() {
            for (let ref in this.$refs) {
                this.$refs[ref].reveal();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.c-home-schedule {
    --home-schedule-direction: column;
    --home-schedule-image: block;
    --home-schedule-gap-image: 0px 0px var(--grid-gutter-2X) 0px;
    display: flex;
    flex-direction: var(--home-schedule-direction);

    @media #{md("xs")} {
        --home-schedule-direction: row;

        --home-schedule-gap-image: 0px var(--grid-gutter-2X) var(--grid-gutter-2X) 0px;
    }
    @media #{md("md")} {
        --home-schedule-image: none;
    }

    &__img {
        display: var(--home-schedule-image);
        margin: var(--home-schedule-gap-image);
    }
}
</style>
