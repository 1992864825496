<template>
    <div class="c-modal-close">
        <button class="c-modal-close__btn" :aria-label="$t('modal.close.aria-label')" @click="closeModal"></button>
    </div>
</template>

<script>
export default {
    methods: {
        closeModal() {
            this.$emit("closeModal", true);
        },
    },
};
</script>

<style lang="scss" scoped>
.c-modal-close {
    --modal-padding: 3px;
    --modal-close-top: calc(
        var(--grid-gutter-half) + 8px - var(--modal-padding)
    ); // 8px is the padding between the paper and the top of the screen;
    --modal-close-right: calc(var(--grid-gutter-half) - var(--modal-padding));
    --modal-close-scale: 0;

    @include transform(scale(var(--modal-close-scale)));
    position: fixed;
    height: 3rem;
    top: var(--modal-close-top);
    right: var(--modal-close-right);
    left: auto;
    z-index: 1;

    @media #{md("sm")} {
        --modal-close-top: calc(var(--grid-gutter-half) - var(--modal-padding));
        --modal-close-right: calc(var(--grid-gutter-half) - var(--modal-padding));
    }

    .c-modal--debug & {
        --modal-close-scale: 1;
    }

    &__btn {
        --modal-close-rotation-before: 45deg;
        --modal-close-rotation-after: -45deg;
        height: 3rem;
        width: 3rem;
        padding: var(--modal-padding);
        cursor: pointer;

        &:hover {
            --modal-close-rotation-before: -45deg;
            --modal-close-rotation-after: 45deg;
        }

        &:before,
        &:after {
            @include transition(0.5s ease transform);
            @include pseudo-el($width: 100%, $height: 2px, $bg: black);

            position: absolute;
            top: 50%;
            left: 0;
        }

        &:before {
            @include transform(rotate(var(--modal-close-rotation-before)));
        }

        &:after {
            @include transform(rotate(var(--modal-close-rotation-after)));
        }
    }
}
</style>
