<template>
    <header class="l-header">
        <nav class="l-nav" itemscope itemtype="http://schema.org/SiteNavigationElement">
            <ul class="l-nav__list">
                <li v-for="(item, i) in nav" :key="i" class="l-nav__item" itemprop="name">
                    <router-link
                        :to="item.url"
                        :title="item.entry.title"
                        :aria-label="item.entry.title"
                        class="l-nav__link"
                        exact
                        itemprop="url"
                    >
                        {{ item.label ? item.label : item.entry.title }}
                    </router-link>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "AppHeader",
    data: () => ({
        currentScroll: 0,
        navIsOpen: false,
    }),
    computed: {
        ...mapState({
            nav: (state) => state.global.navs,
        }),
    },
    methods: {
        openNav() {
            this.navIsOpen = true;

            this.currentScroll = window.scrollY;
            document.documentElement.style.overflowY = "hidden";
        },
        closeNav() {
            this.navIsOpen = false;

            // Add previous scroll
            document.documentElement.style.overflowY = "scroll";
            window.scrollTo(0, this.currentScroll);
        },
    },
    watch: {
        navIsOpen(isOpen) {
            this.$emit("toggleNav", isOpen);
        },
        $route() {
            this.closeNav();
        },
    },
};
</script>
