/*
 * Reveals
 */

// State
const state = {
    isPageRevealed: false,
};

// Getters
const getters = {};

// Actions
const actions = {};

// Mutations
const mutations = {
    updateRevealContents(state, bool) {
        state.isPageRevealed = bool;
    },
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
