<template>
    <div class="c-splash-page-heading-transition" :class="{ 'c-splash-page-heading-transition--visible': isVisible }">
        <switch-site-main
            :is-switching-site="true"
            :next-site="site"
            :is-animated-on-mount="true"
            :is-switch-running="true"
        />
    </div>
</template>

<script>
import SwitchSiteMain from "components/SwitchSite/SwitchSiteMain";
export default {
    components: {
        SwitchSiteMain,
    },
    mounted() {
        this.$nextTick(() => {
            this.initAnimation();
        });
    },

    data() {
        return {
            isVisible: false,
        };
    },
    props: {
        site: {
            type: Object,
            required: false,
            default: null,
        },
    },
    methods: {
        initAnimation() {
            this.isVisible = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-splash-page-heading-transition {
    --opacity: 0;
    --translate: 100px;
    $ease-title: cubic-bezier(0.43, 0, 0.09, 1);
    $transition: 0.5s linear opacity, 1s $ease-title transform;
    @include transition($transition);
    @include transform(translateY(var(--translate)));
    display: flex;
    width: 100%;
    opacity: var(--opacity);
    &--visible {
        --opacity: 1;
        --translate: 0px;
    }
    .c-switch-site-main {
        --scale-site-main: 1;
    }
}
</style>
