<template>
    <div class="c-food-menu">
        <div class="c-food-menu__inner">
            <ul class="c-food-menu__inner__list" v-if="menu">
                <food-menu-group v-for="(item, index) in menu.menuElements" :key="index" :item="item" />
            </ul>
            <hr />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import FoodMenuGroup from "components/FoodMenu/FoodMenuGroup";
export default {
    components: {
        FoodMenuGroup,
    },
    data() {
        return {
            menu: null,
        };
    },
    computed: {
        ...mapState({
            menuId: (state) => state.global.settings.menuId,
        }),
    },

    watch: {
        menuId(val) {
            val ? this.getMenu() : null;
        },
    },

    methods: {
        async getMenu() {
            this.menu = await this.$store.dispatch("menu/loadElement", this.menuId, { root: true });
        },
    },
};
</script>

<style lang="scss" scoped>
.c-food-menu {
    --margin-top-food-menu: var(--grid-gutter-2X);
    --margin-bottom-food-menu: var(--grid-gutter-half);
    @include transform(translateY(100%));
    display: flex;
    flex-direction: row;
    flex: 1;

    min-height: calc(100vh - var(--margin-top-food-menu));
    margin-top: var(--margin-top-food-menu);

    opacity: 0;

    ::-moz-selection {
        text-shadow: none;
        color: var(--color-dark);
        background: var(--accentColour);
    }
    ::selection {
        text-shadow: none;
        color: var(--color-dark);
        background: var(--accentColour);
    }

    @media #{md("sm")} {
        --margin-top-food-menu: var(--grid-gutter-3X);
        --margin-bottom-food-menu: var(--grid-gutter-2X);
    }

    .c-modal--debug & {
        @include transform(translateY(0%));
        opacity: 1;
    }

    &__inner {
        --padding-food-menu-inner: var(--grid-gutter) var(--grid-gutter) 0;
        --food-menu-list-columns: 1;
        --food-menu-list-direction: column;

        width: 100%;
        @media #{md("sm")} {
            --padding-food-menu-inner: var(--grid-gutter-2X) var(--grid-gutter-2X) var(--grid-gutter-half);
            --food-menu-list-columns: 2;
            --food-menu-list-direction: row;
        }
        &__list {
            @include reset-list;
            padding: var(--padding-food-menu-inner);
            margin-bottom: var(--margin-bottom-food-menu);
            background: var(--color-light);

            column-count: var(--food-menu-list-columns);
            @media #{md("sm")} {
                column-gap: var(--grid-gutter-2X);
            }
        }
    }

    // Need that spacer to make the margin-bottom works
    hr {
        border-color: transparent;
    }
}
</style>
