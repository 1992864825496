<template>
    <div class="c-modal" :class="{ 'c-modal--debug': this.isDebug }">
        <modal-close @closeModal="closeModal" />
        <div ref="menuModal" class="c-modal__main" :style="`--accentColour: ${this.accentColour}`">
            <section class="c-modal__main__inner grid">
                <slot></slot>
            </section>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap, CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);
import ModalClose from "components/Modal/ModalClose";
export default {
    components: {
        ModalClose,
    },
    data() {
        return {
            modalTimeline: null,
            isDebug: false,
        };
    },
    computed: {
        ...mapState({
            isOpen: (state) => state.modal.isOpen,
            accentColour: (state) => state.global.currentSite.accentColour,
        }),
    },
    watch: {
        isOpen(bool) {
            this.playTimeline(bool);
        },
    },
    mounted() {
        this.iniTimeline();
    },

    methods: {
        closeModal() {
            this.$store.dispatch("modal/close");
        },
        iniTimeline() {
            CustomEase.create("switchPage", "0.48, 0.04, 0.31, 1.00");
            this.modalTimeline = gsap.timeline({
                paused: true,
            });
            this.modalTimeline
                .to(".c-modal__main", {
                    scaleX: 1,
                    scaleY: 1,

                    ease: "switchPage",
                    duration: 1,
                })
                .to(
                    ".c-food-menu",
                    {
                        opacity: 1,

                        ease: "switchPage",
                        duration: 0,
                    },
                    "-=0.3"
                )
                .to(
                    ".c-food-menu",
                    {
                        y: 0,

                        ease: "switchPage",
                        duration: 1,
                    },
                    "displayMenu-=0.3"
                )
                .to(
                    ".c-modal-close",
                    {
                        "--modal-close-scale": 1,

                        ease: "switchPage",
                        duration: 0.5,
                    },
                    "displayMenu+=0.5"
                );
        },
        playTimeline(bool) {
            bool ? this.modalTimeline.play() : this.modalTimeline.reverse();
        },
    },
};
</script>

<style lang="scss" scoped>
.c-modal {
    --modal-scale: 0;
    position: relative;
    z-index: 99;

    &--debug {
        --modal-scale: 1;
    }
    &__main {
        --accentColour: "#FF4E2B";

        @include full-screen-dom;
        @include transform(scale(var(--modal-scale)));
        background: var(--accentColour);

        display: flex;
        position: fixed;
        overflow-y: scroll;

        &__inner {
            display: flex;
            flex: 1;
            height: 100%;
            max-width: 120rem;
        }
    }
}
</style>
