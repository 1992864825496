/*
 * Craft asset transform w/ srcset
 *
 */

const transforms = [
    {
        name: "square",
        mode: "crop",
        width: 500,
        height: 500,
        quality: 100,
        position: "center-center",
    },
    {
        name: "big",
        mode: "resize",
        width: 1100,
        quality: 90,
        position: "center-center",
    },
    {
        name: "splash",
        mode: "resize",
        width: 560,
        quality: 90,
        position: "center-center",
    },
];

const getAsset = (field = "image", format = false) => {
    let transform;

    const data = `
        url
        title
        width
        height
    `;

    if (format && typeof format == "string") {
        transform = transforms.find((t) => t.name == format);
    }

    if (transform) {
        if (transform.srcset) {
            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${
                transform.height || 0
            }, quality: ${transform.quality || 80}, position: "${
                transform.position || "center-center"
            }", immediately: false) {
                    ${data}
                    mimeType
                    srcset (sizes: ${transform.srcset})
                }
            `;
        } else {
            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${
                transform.height || 0
            }, quality: ${transform.quality || 80}, position: "${
                transform.position || "center-center"
            }", immediately: false) {
                    ${data}
                    mimeType
                }
            `;
        }
    } else {
        return `
            ${field} {
                ${data}
            }
        `;
    }
};

export default getAsset;
