<template>
    <li class="c-splash-page-list-image">
        <asset v-if="image" class="c-splash-page-list-image__asset" :asset="image" ref="bigImg" :to-reveal="true" />
    </li>
</template>

<script>
import Asset from "objects/Asset";
export default {
    components: {
        Asset,
    },
    props: {
        image: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.c-splash-page-list-image {
    --splash-page-list-image-column: 1/2;
    --splash-page-list-image-row: 1/3;
    grid-column: var(--splash-page-list-image-column);
    grid-row: var(--splash-page-list-image-row);
    display: flex;
    justify-content: center;
    @media #{md("sm")} {
        --splash-page-list-image-column: 1/5;
        --splash-page-list-image-row: 1/2;
    }
    &__asset {
        --splash-page-list-image-width: 16rem;
        max-height: 50rem;
        max-width: var(--splash-page-list-image-width);

        @media #{md("xs")} {
            --splash-page-list-image-width: 25rem;
        }
        @media #{md("sm")} {
            --splash-page-list-image-width: 100%;
            padding-right: var(--grid-gutter);
        }
    }
}
</style>
