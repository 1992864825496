<template>
    <li class="c-food-menu-group-child-element">
        <ul class="c-food-menu-group-child-element__main">
            <li>
                <p>
                    <b>{{ child.name }}</b>
                    <small v-if="child.details">&#32;{{ child.details }}</small>
                    <food-menu-group-child-element-option v-if="options" :options="options" />
                </p>
            </li>
            <li>
                <p>
                    <b>{{ child.price }}</b>
                </p>
            </li>
        </ul>
        <div v-if="child.description" class="c-food-menu-group-child-element__description">
            <p>{{ child.description }}</p>
        </div>

        <food-menu-group-child-element-extra v-if="extras" :extras="extras" />
    </li>
</template>

<script>
import FoodMenuGroupChildElementExtra from "components/FoodMenu/FoodMenuGroupChildElement/FoodMenuGroupChildElementExtra";
import FoodMenuGroupChildElementOption from "components/FoodMenu/FoodMenuGroupChildElement/FoodMenuGroupChildElementOption";
export default {
    components: {
        FoodMenuGroupChildElementExtra,
        FoodMenuGroupChildElementOption
    },
    props: {
        child: {
            type: Object,
            required: false,
            default: null
        }
    },
    computed: {
        extras() {
            return this.child.extras && this.child.extras.length && this.child.extras[0].name != "" ? this.child.extras: null
        },
        options() {
            return this.child.options && this.child.options.length ? this.child.options: null
        },
    }
};
</script>

<style lang="scss" scoped>
.c-food-menu-group-child-element {
    --menu-group-child-padding: 1.4rem 0px 1.4rem 0px;
    --menu-group-child-max-width: 100%;
    --food-menu-group-child-border: block;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    padding: var(--menu-group-child-padding);

    page-break-inside: avoid;
    break-inside: avoid-column;

    .c-food-menu-group-child--two-columns & {
        --menu-group-child-padding: 0px 0px 1rem 0px;
        --food-menu-group-child-border: none;
        @media #{md("sm")} {
            --menu-group-child-padding: 0px 0px 0px 0px;
        }
    }

    /* @media #{md("sm")} {
        --menu-group-child-padding: var(--grid-gutter-third) 0px calc(var(--grid-gutter-third) - 0rem) 0px;
    } */

    &:after {
        display: var(--food-menu-group-child-border);
        content: " ";
        position: absolute;

        top: 0px;
        right: 0px;
        left: 0px;

        height: 1px;

        background-color: var(--color-dark);
    }
    &__main {
        @include food-menu-list-element;
        small {
        }
    }
    &__description {
        display: flex;
        margin-top: .4rem;
    }
}
</style>
