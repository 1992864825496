<template>
    <div
        class="c-switch-site"
        :style="`--transform-speed: ${transformSpeed}s;  --next-site-accent-color:${nextSite.accentColour};`"
        :class="className"
    >
        <div class="c-switch-site__inner">
            <!-- purple background -->
            <!-- switch site btn top right -->
            <switch-btn :site-name="nextSite.name" :is-switching-site="isSwitchingSite" />

            <!-- next website fake canvas -->
            <switch-site-main
                :is-switching-site="isSwitchingSite"
                :next-site="nextSite"
                :is-switch-animation-running="isSwitchAnimationInnerRunning"
                :is-init="isInit"
                :is-switch-running="isSwitchRunning"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { gsap, CustomEase } from "gsap/all";
gsap.registerPlugin(CustomEase);

import SwitchBtn from "components/SwitchBtn";
import SwitchSiteMain from "components/SwitchSite/SwitchSiteMain";

import humanizePageIdsMixin from "@/mixins/humanizePageIdsMixin.js";

export default {
    mixins: [humanizePageIdsMixin],
    components: {
        SwitchSiteMain,
        SwitchBtn,
    },
    props: {
        isRevealed: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isInit: true,
            isSwitchAnimationInnerRunning: false,
            isSwitchRunning: false,
            nextSite: null,
            switchTimeline: null,
            transformSpeed: 2,
        };
    },
    created() {
        this.nextSite = this.convertIdUpComingSite(this.currentSite.id);
    },
    mounted() {
        this.toggleIsInit(true);
        this.$nextTick(() => {
            this.initSwitchSiteAnimation();
        });
        window.addEventListener("resizeEnd", this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener("resizeEnd", this.onResize);
    },
    computed: {
        ...mapState({
            isSwitchingSite: (state) => state.global.isSwitchingSite,
            currentSite: (state) => state.global.currentSite,
        }),
        ...mapGetters({
            getSiteById: "global/getSiteById",
        }),
        className() {
            let classname = "c-switch-site";

            if (this.isRevealed) {
                classname += ` c-switch-site--visible`; // please leave this one BEM
            }
            if (this.isSwitchingSite) {
                classname += ` c-switch-site--switch`; // please leave this one BEM
            }

            // classname += ` c-switch-site--debug`; // enable that to debug the switch page

            return classname;
        },
    },
    watch: {
        isSwitchingSite(bool) {
            bool ? this.startSwitchSiteAnimation() : null;
        },
    },
    methods: {
        toggleIsInit(bool) {
            this.isInit = bool;
        },
        initSwitchSiteAnimation() {
            CustomEase.create("switchPage", "0.48, 0.04, 0.31, 1.00");
            // gsap timeline
            this.switchTimeline = gsap.timeline({
                paused: true,
                onComplete: () => {
                    // go to new page
                    window.location.href = this.nextSite.baseUrl;
                },
            });

            this.switchTimeline
                .add(() => {
                    this.isSwitchAnimationInnerRunning = true;
                }, "+=0.5")
                .to(".c-switch-site-main", {
                    "--scale-site-main": 1,
                    ease: "switchPage",
                    duration: 1.5,
                    delay: 0, // currently I transform the page using css so I avoid doing javascript calculus
                });
            this.$nextTick(() => {
                this.toggleIsInit(false);
            });
        },
        calculateSizeToTransform(total, totalToRemove) {
            return total - totalToRemove;
        },
        startSwitchSiteAnimation() {
            this.switchTimeline.play();
            this.isSwitchRunning = true;
        },
        ////////////////////////////////
        //       START RESIZE OR ON PAGE DESTROY
        ////////////////////////////////
        onResize() {
            this.resetSwitchSiteAnimationTimeline();
        },
        resetSwitchSiteAnimationTimeline() {
            this.toggleIsInit(true);
            this.destroyTimeline();
            this.$nextTick(() => {
                this.initSwitchSiteAnimation();
            });
        },
        destroyTimeline() {
            this.switchTimeline ? (this.switchTimeline.kill(), (this.switchTimeline = null)) : null;
        },
        ////////////////////////////////
        //       END RESIZE OR ON PAGE DESTROY
        ////////////////////////////////
    },
};
</script>

<style lang="scss" scoped>
.c-switch-site {
    --height-site-left-clipped: 6rem; // not crazy about this technique, but will work perfectly and save 3hrs of work and many hours of debug
    --rotation-arrow: 0deg;
    --switch-inner-hover: 0rem, 0rem;
    --switch-site-left: 0px;
    --switch-site-top: var(--vh);
    // --switch-transition: 0.5s ease left, var(--transform-speed) cubic-bezier(0.48, 0.04, 0.31, 1) transform;
    --switch-transition: 0.5s ease left, 0.5s ease top,
        var(--transform-speed) cubic-bezier(0.48, 0.04, 0.31, 1) transform;
    --transform-speed: 2s;
    --width-site-left-clipped: 22.2rem; // not crazy about this technique, but will work perfectly and save 3hrs of work and many hours of debug
    --next-site-accent-color: $color-purple;
    @include transition(var(--switch-transition));

    position: fixed;
    display: flex;
    flex: 1;

    width: 100vw;
    height: 100vh;

    top: var(--switch-site-top);
    left: var(--switch-site-left);

    @media #{md('xs')} {
        --switch-site-left: 100vw;
        --switch-site-top: calc(100vh - var(--width-site-left-clipped));
        --rotation-arrow: 270deg;
    }

    &--visible {
        --switch-site-top: calc(var(--vh) - var(--height-site-left-clipped));
        // --switch-site-top: calc(100px - var(--height-site-left-clipped));
        @media #{md('xs')} {
            --switch-site-left: calc(100vw - var(--height-site-left-clipped));
            --switch-site-top: calc(var(--vh) - var(--width-site-left-clipped));
        }
    }

    &:hover {
        --rotation-arrow: -90deg;
        @media #{md('xs')} {
            --rotation-arrow: 360deg;
        }
        &:not(.c-switch-site--switch) {
            --switch-inner-hover: 0rem, -2rem;
            @media #{md('xs')} {
                --switch-inner-hover: -2rem, -2rem;
            }
        }
    }

    &--switch {
        --rotation-arrow: 360deg;
        --switch-inner-hover: 0rem, 0rem;

        @include transform(translate(0px, calc(var(--vh) - var(--vh) * 2 + var(--height-site-left-clipped))));

        @media #{md('xs')} {
            @include transform(
                translate(calc(-100vw + var(--height-site-left-clipped)), calc(-100vh + var(--width-site-left-clipped)))
            );
        }
    }

    &--debug {
        --switch-site-left: 0px;
        --switch-site-top: 0px;
    }

    &__inner {
        @include transform(translate(var(--switch-inner-hover)));
        @include transition(0.5s ease transform);

        position: relative;
        display: flex;
        flex: 1;

        background-color: var(--next-site-accent-color); // MOCK
        overflow: hidden;
    }
    .c-switch-btn {
        // overwirte global color. It's always black so
        color: var(--color-dark);
    }
}
</style>
