<template>
    <ul class="c-contact-buttons">
        <li v-if="settings.phone">
            <btn
                tag="a"
                :label="settings.phone | humanizePhone"
                :href="settings.phone | tel"
                :is-rounded="true"
                :colour="currentSite.textColour"
                :background="currentSite.backgroundColour"
                :is-switch-colour-on-hover="true"
                :aria-label="$t('contact.buttons.phone.aria-label')"
            />
        </li>
        <li v-if="settings.email">
            <btn
                tag="a"
                :label="settings.email"
                :href="mailTo"
                :is-rounded="true"
                :colour="currentSite.textColour"
                :background="currentSite.backgroundColour"
                :is-switch-colour-on-hover="true"
                :aria-label="$t('contact.buttons.email.aria-label')"
            />
        </li>
    </ul>
</template>

<script>
import { mapState } from "vuex";

import Btn from "components/Btn";
export default {
    components: {
        Btn,
    },
    props: {
        settings: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState({
            currentSite: (state) => state.global.currentSite,
        }),
        mailTo() {
            return this.settings.email ? `mailto:${this.settings.email}` : null;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-contact-buttons {
    --contact-btn-gap: var(--grid-gutter-half);
    @include reset-list;

    display: grid;
    gap: var(--contact-btn-gap);

    li {
        @include contact-block-pre-animation;
    }
}
</style>
