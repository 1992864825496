<template>
    <section
        class="c-switch-site-main"
        :class="{ 'c-switch-site-main--init': isInit, 'c-switch-site-main--visible': isSwitchRunning }"
        :style="`--switch-site-background: ${nextSite.backgroundColour}`"
    >
        <!-- black background -->
        <div class="c-switch-site-main__inner">
            <div class="c-switch-site-main__inner__grid">
                <!-- logo next website -->
                <app-heading
                    which-parent="switch"
                    :colour="nextSite.accentColour"
                    :heading="convertIdToHeading(nextSite.id).toLowerCase()"
                    :is-switch-animation-running="isSwitchAnimationRunning"
                    :is-animated-on-mount="isAnimatedOnMount"
                />
            </div>
        </div>
    </section>
</template>

<script>
import humanizePageIdsMixin from "@/mixins/humanizePageIdsMixin.js";
import AppHeading from "layout/AppHeading";
export default {
    mixins: [humanizePageIdsMixin],
    components: {
        AppHeading,
    },
    props: {
        isSwitchAnimationRunning: {
            type: Boolean,
            required: false,
            default: false,
        },
        isAnimatedOnMount: {
            type: Boolean,
            required: false,
            default: false,
        },
        // this is used only to calculate the size of the heading
        isInit: {
            type: Boolean,
            required: false,
            default: false,
        },
        nextSite: {
            type: Object,
            required: false,
            default: null,
        },
        isSwitchRunning: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.c-switch-site-main {
    --switch-site-main-opacity: 0;
    --scale-site-main: 0.8;
    --switch-site-background: black;
    display: flex;
    flex: 1;
    justify-content: center;

    opacity: var(--switch-site-main-opacity);

    pointer-events: none;
    z-index: 2;

    @media #{md('xs')} {
        --scale-site-main: 0.7;
    }

    &--init {
        --scale-site-main: 1;
    }
    &--visible {
        --switch-site-main-opacity: 1;
    }

    &__inner {
        @include transform(scale(var(--scale-site-main)));

        display: flex;
        flex: 1;
        height: 130vh; // 100vh is the height with a scale at 1, if scaled at 0.7, so 100vh * 0.7

        background: var(--switch-site-background);
        overflow: hidden;
        &__grid {
            display: flex;
            justify-content: flex-start;
            flex: 1;
        }

        .l-heading {
            width: 100%;
        }

        svg {
            width: auto;
            max-width: 100%;
            max-height: 43.3rem;
        }
    }
}
</style>
