import { SECTION_HANDLES } from "src/constants";
import { getFilename } from "src/utils";

/*
 * Data parser
 */

// Utilities
const arrayHasItem = (array) => {
    return typeof array !== "undefined" && array.length > 0;
};

export const parseIcon = (icon) => {
    return icon && icon.name ? icon.name.replace("svg-", "") : null;
};

export const parseButtons = (buttons) => {
    let _buttons = [];

    if (!arrayHasItem(buttons)) return;

    buttons.forEach((b) => {
        _buttons.push({
            icon: parseIcon(b.icon),
            label: b.target.text,
            tag: b.target.type == "entry" ? "router-link" : "a",
            target: b.target.target,
            url: b.target.type == "entry" ? `/${b.target.element.uri}` : b.target.url,
            download: b.target.type == "asset" ? getFilename(b.target.url) : null,
        });
    });
    return _buttons;
};

// Parse method
export const parseData = (type, data) => {
    // Standard ID
    if (data.id) data.id = parseInt(data.id);

    // Standard Menu ID
    if (data.menuId) data.menuId = arrayHasItem(data.menuId) ? parseInt(data.menuId[0].id) : null;

    // Standard single asset image
    if (data.image) data.image = arrayHasItem(data.image) ? data.image[0] : null;

    // Clean buttons
    if (data.buttons) data.buttons = parseButtons(data.buttons);

    switch (type) {
        case SECTION_HANDLES.HOME: {
            // DO HOMEPAGE PARSE HERE

            break;
        }

        // ARTICLES
        case SECTION_HANDLES.ARTICLE: {
            data.articleType = arrayHasItem(data.articleType) ? data.articleType[0] : null;

            break;
        }
    }

    return data;
};
