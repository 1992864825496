// State
const state = {
    isOpen: false,
};

// Getters
const getters = {};

// Actions
const actions = {
    open(store) {
        store.commit("openModal", true);
    },
    close(store) {
        store.commit("closeModal", false);
    },
};

// Mutations
const mutations = {
    openModal(state, bool) {
        state.isOpen = bool;
    },
    closeModal(state) {
        state.isOpen = false;
    },
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
