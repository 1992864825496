<template>
    <div :class="className">
        <modal v-if="!isErrorPage">
            <food-menu />
        </modal>
        <transition name="fade">
            <app-heading
                v-if="logoName && !isSplashPage && !isErrorPage"
                which-parent="main"
                :colour="currentSite.accentColour"
                :heading="logoName"
                :is-page-switch="false"
                :is-animated-on-mount="true"
                @animEnd="revealContent"
            />
        </transition>

        <main class="l-main" ref="main">
            <router-view :page="currentPage" @childIsReady="childIsReady" />
        </main>

        <app-footer />
    </div>
</template>

<script>
import AppFooter from "layout/AppFooter";
import AppHeader from "layout/AppHeader";
import AppHeading from "layout/AppHeading";

import { mapState } from "vuex";
import { toKebab } from "src/utils";
import FoodMenu from "components/FoodMenu/FoodMenu";
import Modal from "components/Modal/Modal";

import humanizePageIdsMixin from "@/mixins/humanizePageIdsMixin.js";
export default {
    name: "AppBase",
    mixins: [humanizePageIdsMixin],
    components: {
        AppHeader,
        AppHeading,
        AppFooter,
        FoodMenu,
        Modal,
    },
    data() {
        return {
            navIsOpen: false,
            isRevealed: false,
            logoName: null,
            animations: {
                isChildReady: false,
                isAnimationCompleted: false,
            },
        };
    },
    computed: {
        ...mapState({
            currentPage: (state) => state.global.currentPage,
            currentPageName: (state) => state.global.currentPageName,
            currentSite: (state) => state.global.currentSite,
        }),
        className() {
            let classname = "l-wrapper";

            if (this.currentPageName) {
                classname += ` p-${toKebab(this.currentPageName)}`;
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += " nav-is-open";
            }

            return classname;
        },
        isSplashPage() {
            return window.__initialData__.currentSite.id === 3;
        },
        isErrorPage() {
            return this.currentPage.uri === "404";
        },
        isPageReady() {
            return this.animations.isChildReady && this.animations.isAnimationCompleted;
        },
    },
    created() {
        this.setSiteName();
    },
    beforeDestroy() {
        this.timeoutDestroyer();
    },
    methods: {
        setSiteName() {
            this.logoName = this.convertIdToHeading(window.__initialData__.currentSite.id);
        },
        toggleNav(isOpen) {
            this.navIsOpen = isOpen;
        },
        revealContent() {
            this.toggleStates("isAnimationCompleted", true);

            this.$nextTick(() => {
                this.triggerChildAnimations();
            });
        },
        childIsReady() {
            this.toggleStates("isChildReady", true);

            this.$nextTick(() => {
                this.triggerChildAnimations();
            });
        },

        triggerChildAnimations() {
            // watch is expensive, this solutions is way cheaper and work fine

            this.isPageReady ? this.revealGlobally() : null;
        },
        toggleStates(name, bool) {
            this.animations[name] = bool;
        },
        revealGlobally() {
            // this is crazy, theoricly no reveals should run until children component are ready because
            // I check from the then, then each child wait to mount && that is reveal globally
            // but for some reason, sometimes font are not even fully loaded etc
            // This is really specific to right now because staging is extermely slow, however client will check on this website so it needs to run with fallbacks
            this.timeout = setTimeout(() => {
                this.$store.commit("reveals/updateRevealContents", true);
                console.log("updateRevealContents");
                this.timeoutDestroyer();
            }, 300);
        },
        timeoutDestroyer() {
            this.revealTimeout ? (clearTimeout(this.revealTimeout), (this.revealTimeout = null)) : null;
        },
    },
};
</script>

<style lang="scss">
.l-wrapper {
    background-color: var(--site-bg);
    color: var(--site-text-color);
    height: 100%;
    display: block;
}

.l-main {
}

.fade-enter-active,
.fade-leave-active {
    $fade-transition: linear 0.5s opacity, ease 0.5s transform;
    @include transition($fade-transition);
}
.fade-enter,
.fade-leave-to {
    @include transform(translateY(5rem));
    opacity: 0;
}
</style>
