<template>
    <li
        class="c-splash-page-list"
        :class="`c-splash-page-list--${site.id}`"
        :style="` --splash-list-background-colour: ${site.backgroundColour}; `"
    >
        <ul class="c-splash-page-list__inner grid">
            <!-- img -->

            <splash-page-list-image :image="image" />
            <!-- logo -->

            <splash-page-list-main :site="site" />

            <!-- arrow -->
        </ul>
    </li>
</template>

<script>
import SplashPageListImage from "components/SplashPage/SplashPageList/SplashPageListImage";
import SplashPageListMain from "components/SplashPage/SplashPageList/SplashPageListMain";
export default {
    components: {
        SplashPageListImage,
        SplashPageListMain,
    },
    props: {
        site: {
            type: Object,
            required: false,
            default: null,
        },
        image: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.c-splash-page-list {
    --splash-list-background-colour: var(--color-dark);
    --splash-list-after-visiblity: none;
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: var(--splash-list-background-colour);
    padding: var(--grid-gutter-half);

    max-height: calc(var(--vh) / 2);

    @media #{md("sm")} {
        max-height: auto;
    }

    &--2 {
        --splash-list-after-visiblity: block;
    }

    &__inner {
        --template-columns-inner: 1;
        --template-row-inner: 2;
        --splash-gap: var(--grid-gutter-half);
        --splash-translate: -10%;
        @include transition(0.5s ease transform);
        @include transform(translateX(var(--splash-translate)));
        @include reset-list;

        display: grid;
        grid-template-columns: repeat(var(--template-columns-inner), 1fr);
        grid-template-rows: repeat(var(--template-row-inner), 1fr);
        gap: var(--splash-gap);
        padding: 0px;

        .p-splash--ready & {
            --splash-translate: 0%;
        }

        @media #{md("sm")} {
            --template-columns-inner: 12;
            --template-row-inner: 1;
        }
    }
}
</style>
