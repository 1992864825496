<template>
    <div v-if="image" :class="className" :style="`--ratio: ${ratio}`">
        <source
            v-if="image.srcset"
            :data-srcset="lazy ? image.srcset : false"
            :srcset="!lazy ? image.srcset : false"
            :type="image.mimeType"
        />
        <img
            class="o-asset__img"
            :class="{ lazyload: lazy }"
            ref="image"
            :src="lazy ? '/static/images/blank.gif' : image.url"
            :data-sizes="lazy ? 'auto' : false"
            :data-src="lazy ? image.url : false"
            :alt="title ? title : image.title"
            :width="image.width"
            :height="image.height"
            @lazyloaded="loaded()"
            @load="loaded()"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { round } from "src/utils";
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);
export default {
    name: "Asset",
    props: {
        asset: {
            type: Object | Array,
            default: () => [],
        },
        url: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        lazy: {
            type: Boolean,
            default: false,
        },
        cover: {
            type: Boolean,
            default: false,
        },
        contain: {
            type: Boolean,
            default: false,
        },
        toReveal: {
            type: Boolean,
            default: false,
        },
        initialState: {
            type: Object,
            default: () => {},
        },
        revealOptions: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        isLoaded: false,
    }),
    created() {
        this.isLoaded = this.lazy;
    },
    mounted() {
        if (this.toReveal) {
            this.setInitialState();
            this.triggerAnimation();
        }
    },
    beforeDestroy() {
        this.timeline ? (this.timeline.kill(), (this.timeline = null)) : "";
    },

    computed: {
        ...mapState({
            isPageRevealed: (state) => state.reveals.isPageRevealed,
        }),
        className() {
            let classname = "o-asset";

            if (this.cover) {
                classname += " -cover";
            }

            if (this.contain) {
                classname += " -contain";
            }

            if (this.lazy) {
                classname += " -lazy";

                if (this.loaded) {
                    classname += " is-loaded";
                }
            }

            return classname;
        },
        image() {
            let image = this.asset;

            // If is array, take first index
            if (Array.isArray(image) && image.length > 0) {
                return image[0];
            }

            if (image && image.url) {
                return image;
            }

            if (this.url) {
                return {
                    url: this.url,
                    title: this.title,
                    width: false,
                    height: false,
                };
            }
        },
        width() {
            return this.image.width;
        },
        height() {
            return this.image.height;
        },
        ratio() {
            if (!this.width || !this.height) {
                return null;
            }

            return round(this.width / this.height, 3);
        },
        styles() {
            let styles = false;

            if (this.ratio) {
                styles = `--asset-ratio: ${this.ratio};`;
            }

            return styles;
        },
    },
    watch: {
        isPageRevealed() {
            this.triggerAnimation();
        },
    },

    methods: {
        triggerAnimation() {
            this.isPageRevealed && !this.timeline && this.toReveal ? this.reveal() : null;
        },

        loaded() {
            this.isLoaded = true;
            this.$emit("loaded");
        },
        setInitialState() {
            gsap.set(this.$el, {
                opacity: 0,
                y: "100",
                ...this.initialState,
            });
        },
        reveal() {
            this.timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: this.$el,
                    start: "top bottom-=20%",
                },
            });

            this.timeline.to(this.$el, {
                opacity: 1,
                y: 0,
                duration: 0.7,
                ease: "power1.out",
                ...this.revealOptions,
            });
        },
    },
};
</script>

<style lang="scss">
.o-asset {
    &--cover {
        .o-asset__img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
        }
    }
}

.o-asset__img {
    @include img;
}
</style>
