<template>
    <section class="c-error-bottom" :style="`--accentColour:${site.accentColour}; --textColour:${site.textColour}`">
        <div class="c-error-bottom__inner">
            <div class="c-error-bottom__left">
                <icon :icon="`${whichLogo}-logo`" :style="` color: var(--color-dark)`" />
            </div>
            <ul class="c-error-bottom__right">
                <li>
                    <p>{{ $t("error.copy") }}</p>
                </li>
                <li>
                    <btn
                        tag="a"
                        :label="$t('error.button.label', { siteName: site.name })"
                        :href="site.baseUrl"
                        :is-rounded="true"
                        :is-switch-colour-on-hover="true"
                        :aria-label="$t('error.button.label', { siteName: site.name })"
                        :colour="site.textColour"
                        :background="site.backgroundColour"
                    />
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import Btn from "components/Btn";
import Icon from "objects/Icon";
export default {
    components: {
        Btn,
        Icon,
    },
    props: {
        site: {
            type: Object,
            required: false,
            default: null,
        },
    },
    computed: {
        isCurrentSiteRamen() {
            return this.site.id === 2;
        },
        whichLogo() {
            return this.isCurrentSiteRamen ? "ramen" : "hono";
        },
    },
};
</script>

<style lang="scss" scoped>
.c-error-bottom {
    --accentColour: var(--color-dark);
    --textColour: var(--color-dark);
    --margin-top-error-bottom: var(--grid-gutter);
    margin-top: var(--margin-top-error-bottom);
    @media #{md("sm")} {
        --margin-top-error-bottom: var(--grid-gutter-2X);
    }
    &__inner {
        --error-bottom-direction: column;
        display: flex;
        flex-direction: var(--error-bottom-direction);
        @media #{md("sm")} {
            --error-bottom-direction: row;
        }
    }
    &__left {
        --display-error-bottom-left: flex;
        --display-error-bottom-justify: flex-start;
        --error-bottom-left-margin: 0rem 0rem var(--grid-gutter) 0rem;
        display: var(--display-error-bottom-left);
        flex-direction: column;
        justify-content: var(--display-error-bottom-justify);
        margin: var(--error-bottom-left-margin);
        .o-icon {
            font-size: 7rem;
        }
        @media #{md("sm")} {
            --display-error-bottom-left: flex;
            --display-error-bottom-justify: flex-end;
            --error-bottom-left-margin: 0rem var(--grid-gutter) 0rem 0rem;
        }
    }
    &__right {
        @include reset-list;
        li {
            margin-bottom: var(--grid-gutter);
            &:last-child {
                margin-bottom: 0px;
            }
        }
        p {
            color: var(--textColour);
            max-width: 20rem;
            &::selection {
                background-color: var(--accentColour);
            }
        }
    }
}
</style>
