/*
 * Transition
 */

// State
const state = {
    whichSiteIsSelected: null,
    isSplashDisabled: false,
};

// Getters
const getters = {};

// Actions
const actions = {};

// Mutations
const mutations = {
    setWhichSiteIsSelected(state, siteId) {
        state.whichSiteIsSelected = siteId;
    },
    setDisabledSplash(state, bool) {
        state.isSplashDisabled = bool;
    },
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
