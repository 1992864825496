<template>
    <app-section>
        <div
            class="p-home-main"
            :style="` --text-colour:${currentSiteTextColour};  --accent-colour:${currentSiteAccentColour}`"
        >
            <div class="p-home-main__nav">
                <div class="p-home-main__nav-inner">
                    <contact-block ref="contactBlock" />
                    <app-nav ref="nav" />
                </div>
                <div class="p-home-main__nav__img">
                    <asset :asset="page.bigImage" v-if="page.bigImage" ref="bigImg" :to-reveal="true" />
                </div>
            </div>
            <home-schedule v-if="page.bigImage" ref="homeSchedule" :image="page.bigImage" />
            <!-- :is-heading-revealed="isHeadingRevealed" -->

            <home-left :page="page" />

            <div class="bottom-img">
                <asset :asset="page.smallImage" v-if="page.smallImage" ref="smallImg" :to-reveal="true" />
            </div>
        </div>

        <switch-site :is-revealed="isPageRevealed" />
    </app-section>
</template>

<script>
import { mapState } from "vuex";
import { SECTION_HANDLES } from "src/constants";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import AppNav from "layout/AppNav";
import AppSection from "layout/AppSection";
import Asset from "objects/Asset";
import ContactBlock from "components/ContactBlock";
import HomeLeft from "components/Home/HomeLeft";
import HomeSchedule from "components/Home/HomeSchedule";
import SwitchSite from "components/SwitchSite/SwitchSite";

export default {
    name: "Home",
    components: {
        AppNav,
        AppSection,
        Asset,
        ContactBlock,
        HomeLeft,
        HomeSchedule,
        SwitchSite,
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null,
        };
    },
    props: {
        page: {
            type: Object,
            required: false,
            default: null,
        },
        isHeadingRevealed: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            SECTION_HANDLES,
            isRevealed: false,
            isReady: false,
            intervalLoopLatency: null,
        };
    },
    computed: {
        ...mapState({
            currentSiteTextColour: (state) => state.global.currentSite.textColour,
            currentSiteAccentColour: (state) => state.global.currentSite.accentColour,
            isPageRevealed: (state) => state.reveals.isPageRevealed,
        }),
    },

    mounted() {
        this.latencyFixer();
    },
    methods: {
        latencyFixer() {
            // there is an issue on the server when the latency is too high. Unsure that all refs from children are ready first before to run the animation
            this.intervalLoopLatency = setInterval(() => {
                !this.isReady ? this.findAllReveal() : this.timeoutDestroyer();
            }, 200);
        },
        findAllReveal() {
            // there is an issue on the server when the latency is too high. Unsure that all refs from children are ready first before to run the animation
            Object.keys(this.$refs).forEach((key, index) => {
                // console.log("key", index);
                //use key and value here
                index >= 3 ? (this.$emit("childIsReady", true), (this.isReady = true)) : null;
            });
        },
        timeoutDestroyer() {
            this.intervalLoopLatency
                ? (clearTimeout(this.intervalLoopLatency), (this.intervalLoopLatency = null))
                : null;
        },
    },
};
</script>

<style lang="scss">
.p-home-main {
    --text-colour: var(--color-dark);
    --accent-colour: var(--color-red);
    color: var(--text-colour);
    padding-bottom: var(--grid-gutter);
    display: grid;
    gap: calc(2 * var(--grid-gutter));

    @media #{md('md')} {
        grid-template-columns: 1fr auto;
    }

    &__nav {
        display: grid;
        gap: var(--grid-gutter);

        &-inner {
            display: grid;
            align-items: flex-start;
            gap: 0;

            @media #{md('xs')} {
                gap: var(--grid-gutter-half);
            }

            @media #{md('sm')} {
                grid-template-columns: 1fr 1fr;
            }
        }

        &__img {
            --home-img-large: none;
            display: var(--home-img-large);
            @media #{md('md')} {
                --home-img-large: block;
            }
        }
    }

    .bottom-img {
        width: max(22rem, 25vw);

        @media #{md('sm')} {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            margin-top: auto;
        }
    }
}
</style>
