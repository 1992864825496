<template>
    <app-section class="l-heading" :class="`l-heading--${whichParent}`">
        <div v-if="isVisible" ref="headingTop" class="l-heading__top">
            <div v-for="n in lengthList" :key="n" class="l-heading__title t-t1">
                <icon
                    class="l-heading__logo"
                    :class="{ 'l-heading__logo--visible': isItemLast(n) }"
                    icon="hono"
                    :style="`color:${colour};`"
                />
                <span
                    ref="headingTopSpan"
                    class="l-heading__title__copy"
                    :class="{
                        'l-heading__title__copy--hide': !isItemLast(n),
                    }"
                >
                    <svg viewBox="0 0 966 433" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <use :xlink:href="convertSiteNameToLogoName(heading)"></use>
                    </svg>
                </span>
            </div>
        </div>
    </app-section>
</template>

<script>
import { gsap, CustomEase } from "gsap/all";
import humanizePageIdsMixin from "mixins/humanizePageIdsMixin.js";
gsap.registerPlugin(CustomEase);

// import {CustomEase} from "@/assets/vendors/CustomEase.min.js";

import Icon from "objects/Icon";
import AppSection from "layout/AppSection";

export default {
    mixins: [humanizePageIdsMixin],
    components: { Icon, AppSection },

    data() {
        return {
            gsapAnimations: {
                intro: null,
                switch: null,
            },
            isAnimationCompleted: false,
            isVisible: true,
            lengthList: 1,
            options: {},
            timeline: null,
            windowWidth: 0,
        };
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
        colour: {
            type: String,
            required: false,
            default: "#ff4e2b",
        },

        //  isPageSwitch && isSplashPage can be refactored into whichPage

        whichParent: {
            type: String,
            required: false,
            default: "main",
        },

        isSwitchAnimationRunning: {
            type: Boolean,
            required: false,
            default: false,
        },
        isAnimatedOnMount: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.createCustomAnimation();
            this.getTitleHeight();
            this.initAnimationsOnMount();
        });

        window.addEventListener("resizeEnd", this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener("resizeEnd", this.onResize);
        this.destroyTimeline();
    },
    computed: {
        isPageSwitch() {
            return this.whichParent === "switch";
        },
    },
    watch: {
        isSwitchAnimationRunning(bool) {
            this.$nextTick(() => {
                bool && this.isPageSwitch ? this.playRevealTimeline("switch") : null;
            });
        },
    },
    methods: {
        createCustomAnimation() {
            CustomEase.create("titleScroll", "0.43, 0.00, 0.09, 1.00");
        },
        setCurrentWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        ////////////////////////////////
        //       START CALCULATE LIST LENGTH THEN INIT
        ////////////////////////////////
        initAnimationsOnMount() {
            // set current window width (to avoid unless recalculation on reseiz)
            this.setCurrentWindowWidth();
            this.isPageSwitch ? this.initSwitch() : this.initReveal();
        },

        initSwitch() {
            this.isVisible = true;
            // calculate the height of the screen to always display enough title to cover the full screen
            const lengthList = window.innerHeight / this.calculateHeightOfDomElement(".l-heading__title");
            // avoid dynamicly adding length as it leads bug with GSAP for some reason
            // to do that, simply remove the list
            this.isVisible = false;
            // then update the length of the list
            this.lengthList = Math.round(lengthList + 1);
            // then display the list with the proper amount of lines
            this.isVisible = true;
            // wait next tick to avoid some bugs with GSAP
            this.$nextTick(() => {
                this.initSwitchAnimation();
            });
        },
        initReveal() {
            // wait next tick to avoid some bugs with GSAP
            this.$nextTick(() => {
                // init the animation and play the animation right after if its main
                this.initIntroAnimation();
            });
        },

        ////////////////////////////////
        //       END CALCULATE LIST LENGTH THEN INIT
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        //======= START SWITCH PAGE ANIMATION (SPLASH && SWITCH RESTAURANT) =======//

        initSwitchAnimation() {
            const headingTop = this.$refs.headingTop;

            this.gsapAnimations.switch = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.isAnimationCompleted = true;
                },
            });

            this.gsapAnimations.switch
                .to(
                    headingTop,
                    {
                        "--heading-top-opacity": 1,
                        duration: 0.8,
                    },
                    "scrollAnimation"
                )
                .to(
                    headingTop,
                    {
                        y: `-${this.totalToTranslate()}px`,
                        duration: 1.5,
                        ease: "titleScroll",
                    },
                    "scrollAnimation"
                );

            this.isAnimatedOnMount ? this.playRevealTimeline("switch") : null;
        },
        totalToTranslate() {
            return (
                this.calculateHeightOfDomElement(".l-heading__top") -
                this.calculateHeightOfDomElement(".l-heading__title") +
                0
            );
        },
        calculateHeightOfDomElement(className) {
            return this.$el.querySelector(className).getBoundingClientRect().height;
        },

        //======= END SWITCH PAGE ANIMATION (SPLASH && SWITCH RESTAURANT) =======//

        //======= START PAGE LOAD =======//

        initIntroAnimation() {
            const headingTop = this.$refs.headingTop;
            const headingTopSpan = this.$refs.headingTopSpan;

            this.gsapAnimations.intro = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.isAnimationCompleted = true;
                    this.$emit("animEnd");
                },
            });

            this.gsapAnimations.intro
                .to(headingTop, {
                    "--heading-top-hidden-title": 0,
                    duration: 0.5,
                    ease: "linear",
                })

                .to(
                    headingTop,
                    {
                        "--heading-top-logo-width": "100%",
                        delay: -0.3,
                        ease: "power1.in",
                        duration: 0.7,
                    },
                    "animateLogo"
                )
                .to(
                    headingTop,
                    {
                        "--heading-top-logo-opacity": 1,
                        x: 0,
                        ease: "power1.out",
                        duration: 0.6,
                    },
                    "animateLogo"
                )
                .to(
                    headingTopSpan,
                    {
                        x: this.responsiveTranslate(),
                        duration: 1,
                        delay: -0.3,
                        ease: "titleScroll",
                    },
                    "animateLogo"
                );

            this.isAnimatedOnMount ? this.playRevealTimeline("intro") : null;
        },

        responsiveTranslate() {
            // can be a mixin
            return window.innerWidth > 1024 ? 60 : 20;
        },

        //======= END PAGE LOAD =======//

        playRevealTimeline(timeline) {
            !this.isAnimationCompleted
                ? this.gsapAnimations[timeline].play()
                : this.gsapAnimations[timeline].progress(1, false);
        },

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        isItemLast(index) {
            return index >= Number(this.lengthList);
        },

        getTitleHeight() {
            const height = this.calculateHeightOfDomElement(".l-heading__title");

            this.$el.style.setProperty("--height", height + "px");
            return height;
        },

        onResize() {
            if (this.windowWidth === window.innerWidth) return;
            // destroy timeline
            this.destroyTimeline("switch");
            this.destroyTimeline("intro");
            // destroy timeline
            this.clearAllGSAPProps();

            // restart timeline
            this.$nextTick(() => {
                this.getTitleHeight();
                this.initAnimationsOnMount();
            });
        },
        clearAllGSAPProps() {
            const headingTop = this.$refs.headingTop;
            const headingTopSpan = this.$refs.headingTopSpan;

            gsap.set(headingTop, { clearProps: "all" });
            gsap.set(headingTopSpan, { clearProps: "all" });
        },

        ////////////////////////////////
        //       START DESTROY LIST
        ////////////////////////////////
        destroyTimeline(name) {
            this.gsapAnimations[name] ? (this.gsapAnimations[name].kill(), (this.gsapAnimations[name] = null)) : null;
        },
        ////////////////////////////////
        //       END DESTROY LIST
        ////////////////////////////////
    },
};
</script>

<style lang="scss">
.l-heading {
    --heading-top-opacity: 1;
    --heading-top-title-opacity: 1;
    --heading-top-hidden-title: 1;

    --heading-top-logo-width: 0;
    --heading-top-logo-opacity: 0;

    --heading-top-hidden-margin-bottom: var(--height);
    --heading-top-hidden-padding-bottom: var(--grid-gutter);
    --heading-top-hidden-padding-top: var(--grid-gutter-half);

    position: relative;
    margin-bottom: var(--heading-top-hidden-margin-bottom);
    padding-bottom: var(--heading-top-hidden-padding-bottom);
    padding-top: var(--heading-top-hidden-padding-top);

    @media #{md("sm")} {
        --heading-top-hidden-padding-bottom: var(--grid-gutter-2X);
    }

    &--switch {
        --heading-top-opacity: 1;
        --heading-top-title-opacity: 1;
    }
    &--splash {
        --heading-top-opacity: 1;
        --heading-top-title-opacity: 1;

        --heading-top-hidden-padding-top: 0px;
    }

    &__top {
        display: grid;
        gap: var(--grid-gutter);

        position: absolute;
        width: 100%;

        opacity: var(--heading-top-opacity);
    }

    &__title {
        @include transition(0.6s ease all); // not entirely sure what Mo was using it for. I leave it like that for now
        position: relative;
        display: flex;
        align-items: center;

        max-height: 40rem; //not crazy about that, I might come back later on that.

        opacity: var(--heading-top-title-opacity);
        &__copy {
            --width-copy: auto;
            display: flex;
            position: relative;
            height: 100%;
            width: var(--width-copy);
            .is-ios &,
            .is-mac-os.is-safari & {
                --width-copy: 100%; // flex: 0.55; keep it there. It was like that before.
            }
            // if it go one more indentation so create a new component
            svg {
                --max-width: 65vw;
                --width: 100%;
                height: 100%;
                overflow: inherit;

                width: var(--width); // https://mambomambo-team.atlassian.net/browse/HONO-162
                max-width: var(--max-width);

                @media #{md("md")} {
                    --max-width: auto;
                    --width: auto;
                }
                .is-ios &,
                .is-mac-os.is-safari & {
                    min-width: 42vw;
                }
            }

            &--hide {
                opacity: var(--heading-top-hidden-title);
            }
            &--last {
                opacity: 0;
            }
            &--first {
                opacity: 1;
            }
        }
    }

    &__logo {
        @include responsive-type($min-width: 375, $max-width: 1280, $min-font: 35, $max-font: 135);
        display: var(--heading-top-logo-display);
        flex: 0;
        width: var(--heading-top-logo-width);

        opacity: 0; // all logo are invsibile except the last one, improving responsive

        &--visible {
            opacity: var(--heading-top-logo-opacity);
        }

        svg {
            max-height: 38rem;
        }
    }
}
</style>
